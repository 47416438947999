import { URL_PARSE_REGEX } from '@/constant/constants';

export const getQueryParametersFromUrl = (...params) => {
    if (!params.length) {
        return undefined;
    }

    let queryEntries = window
        .location
        .search
        .replace('?', '')
        .split('&')
        .map((t) => t.split('='));
    const result = queryEntries.reduce((acc, [key, val]) => {
        if (params.includes(key)) acc[key] = val;
        return acc;
    }, {});
    queryEntries = queryEntries.filter(([key]) => !params.includes(key));
    const queryString = queryEntries.length ? `?${queryEntries.map((query) => query.join('=')).join('&')}` : '';
    return {
        params: result,
        cleanUrl: window.location.origin + window.location.pathname + queryString + window.location.hash,
    };
};

/**
 * Parses plain text to find links in it
 * @param text - plain text to parse
 * @param {Array.<RegExp>} blacklistPatterns - blacklist patterns
 * @return {RegExpMatchArray | Array} - array of links or empty array if none found
* */
export function getUrlsFromText(text, blacklistPatterns) {
    const matches = text.match(URL_PARSE_REGEX);
    if (matches === null) return [];
    if (blacklistPatterns) return matches.filter((m) => !blacklistPatterns.some((e) => e.test(m)));

    return matches;
}
