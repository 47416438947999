import axios from 'axios';

import { parseQuery } from '@/utils';

class CDPService {
    #trackCode;

    constructor() {
        this.#trackCode = CDPService.#parseTrackCode();
    }

    /**
     * @return {string|undefined}
     */
    static #parseTrackCode() {
        const { trid } = parseQuery(document.location.search);

        return trid;
    }

    /**
     * @return {string|undefined}
     */
    getTrackCode() {
        return this.#trackCode;
    }

    visit() {
        if (!this.#trackCode) {
            return;
        }

        const query = new URLSearchParams({
            track: this.#trackCode,
        });

        axios.get(`/affiliate:cdp_link_visit?${query.toString()}`)
            .catch((e) => {
                console.error('Error send affiliate:link_visit', e);
            });
    }
}

export const cdpService = new CDPService();
