import createControllablePromise from '@/utils/createControllablePromise';

/**
 * Перечисление доступных для использования промисов
 * @enum {string}
 */
export const PromiseEnum = Object.freeze({
    APP_HASH_DATA: 'appHashData',
    AUTH: 'auth',
    AUTH_TOKEN: 'authToken',
    DOMAIN_CONTENT_TYPE: 'domainContentType',
    INVEST: 'invest',
    LANG: 'lang',
    LOCALE: 'locale',
    USER_BALANCE: 'userBalance',
});

const promises = Object.values(PromiseEnum);

/**
 * Обработчик для проксирования состояния в основном геттере модуля
 * Если разработчик обращается за несуществующим промисом, вместо неявного возврата `undefined` выбрасывается ошибка
 */
const proxyHandler = {
    get(target, prop) {
        if (!promises.includes(prop)) {
            throw new Error(`Unable to get promise by name "${prop}": no such promise`);
        }

        return target[prop];
    },
};

export default {
    state() {
        return promises.reduce((acc, promise) => ({ ...acc, [promise]: createControllablePromise() }), {});
    },

    getters: {
        promiseByName(state) {
            return new Proxy(state, proxyHandler);
        },
    },

    mutations: {
        resetPromiseByName(state, name) {
            if (!promises.includes(name)) {
                throw new Error(`Unable to reset promise by name "${name}": no such promise`);
            }

            state[name] = createControllablePromise();
        },

        resolvePromiseByName(state, name) {
            if (!promises.includes(name)) {
                throw new Error(`Unable to resolve promise by name "${name}": no such promise`);
            }

            state[name]?.resolve();
        },
    },
};
