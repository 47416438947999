/* eslint-disable no-use-before-define, no-underscore-dangle */

// to add new feature support
// 1) add feature to EnvironmentFeature
// 2) add new test in EnvironmentFeaturesService.initFeatureTests

export const EnvironmentFeature = {
    WEBP: 'EnvironmentFeature:WEBP',
};

class EnvironmentFeaturesService {
    static EnvironmentFeature = EnvironmentFeature

    _featureStatusMap = {}

    _featureTestMap = {}

    _featureResolveStatus = {}

    _featureResolveBodyClass = {}

    _trackedFeatures = []

    _asyncFeatureTestTasksMap = {}

    constructor() {
        this.runFeatureTest = this.runFeatureTest.bind(this);

        this.initFeatureTests();
        this.runFeatureTests();
    }

    initFeatureTests() {
        this.registerFeatureTest(EnvironmentFeature.WEBP, testWEBPUsase, false, 'webp');
    }

    runFeatureTests() {
        this._trackedFeatures.forEach(this.runFeatureTest);
    }

    runFeatureTest(environmentFeature) {
        const result = this._featureTestMap[environmentFeature]();

        if (result instanceof Promise) this.handleAsyncFeatureTestResult(environmentFeature, result);
        else this.handleSyncFeatureTestResult(environmentFeature, result);
    }

    handleSyncFeatureTestResult(environmentFeature, result) {
        this.resolveFeatureTest(environmentFeature, result);
    }

    async handleAsyncFeatureTestResult(environmentFeature, promise) {
        this._asyncFeatureTestTasksMap[environmentFeature] = promise;

        try {
            const result = await promise;
            this.resolveFeatureTest(environmentFeature, result);
        } catch {
            this.resolveFeatureTest(environmentFeature);
        } finally {
            delete this._asyncFeatureTestTasksMap[environmentFeature];
        }
    }

    resolveFeatureTest(environmentFeature, result) {
        this._featureResolveStatus[environmentFeature] = true;

        if (result !== undefined) this._featureStatusMap[environmentFeature] = result;

        if (this._featureResolveBodyClass[environmentFeature]) {
            document.body.classList.toggle(this._featureResolveBodyClass[environmentFeature], result);
        }
    }

    isFeatureStatusResolved(environmentFeature) {
        return this._featureResolveStatus[environmentFeature];
    }

    isFeatureSupported(environmentFeature) {
        if (this.isFeatureStatusResolved(environmentFeature) === false) {
            const log = __IS_DEV__ ? console.error : console.warn;
            log(`
                isFeatureSupported called for feature(${environmentFeature}) with unresolved status.
                Make sure that feature test is done by using 'await getFeatureTestPromise(feature)'.
            `);
        }

        return this._featureStatusMap[environmentFeature];
    }

    registerFeatureTest(environmentFeature, testFunction, fallbackResult, bodyClass) {
        this._trackedFeatures.push(environmentFeature);
        this._featureResolveStatus[environmentFeature] = false;
        this._featureTestMap[environmentFeature] = testFunction;
        this._featureStatusMap[environmentFeature] = fallbackResult;
        this._featureResolveBodyClass[environmentFeature] = bodyClass;
    }

    getFeatureTestPromise(environmentFeature) {
        if (this._asyncFeatureTestTasksMap[environmentFeature] !== undefined) {
            return this._asyncFeatureTestTasksMap[environmentFeature];
        }

        return Promise.resolve();
    }
}

const environmentFeaturesService = new EnvironmentFeaturesService();

export function isEnvironmentFeatureSupported(environmentFeature) {
    return environmentFeaturesService.isFeatureSupported(environmentFeature);
}

async function testWEBPUsase() {
    const image = new Image();
    image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';

    return new Promise((resolve) => {
        image.onerror = () => resolve(false);

        image.onload = (event) => {
            // if the event is from 'onload', check the see if the image's width is
            // 1 pixel (which indiciates support). otherwise, it fails
            resolve(event && event.type === 'load' ? image.width === 1 : false);
        };
    });
}
