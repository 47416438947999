import { AppStorage, FIREBASE_PUSH_API_TOKEN } from '@/plugins/AppStorage';
import createControllablePromise from '@/utils/createControllablePromise';

/**
 * @typedef {object} StorageInterface
 *   @property {function(string, any): any} getOr
 *   @property {function(string): void} remove
 *   @property {function(string, any): void} set
 */

class PushApiController {
    /**
     * Асинхронный флаг, сообщающий, готовы ли необходимые для запуска сервиса данные
     * @type {Promise<void>}
     * @readonly
     */
    isReady = createControllablePromise({
        onReject: () => {
            this.#hasError = true;
        },
    });

    /**
     * Функция, выполняемая при удалении токена
     * Подразумевается, что эта функция удаляет токен на бэке и из storage.
     * @type {function(string): void}
     */
    deleteTokenCallback;

    /**
     * Функция, выполняемая при сохранении токена
     * Подразумевается, что эта функция сохраняет токен на бэке и в storage.
     * @type {function(string): void}
     */
    saveTokenCallback;

    /**
     * Функция, проверяющая готовность всех основных компонентов необходимых для работы сервиса.
     * Вызывается после регистрации каждого основного компонента. (saveTokenCallback, deleteTokenCallback, ...)
     * Если все готово функция резолвит промис '#isReady'
     * @type {function(): boolean}
     */
    checkReadyState;

    /**
     * Флаг, сообщающий о наличии ошибки, мешающей сервису начать работу
     * @type {boolean}
     * @private
     */
    #hasError = false;

    /**
     * Флаг, сообщающий, запущен ли сервис в данный момент
     * @type {boolean}
     * @private
     */
    #isRunning = false;

    /**
     * Функция с кастомной логикой вызываемая при запуске push сервиса
     * @type {function(): Promise<void>}
     * @private
     */
    #onStart;

    /**
     * Функция с кастомной логикой вызываемая при остановке push сервиса
     * @type {function(): Promise<void>}
     * @private
     */
    #onStop;

    /**
     * Функция проверяющая даны ли права на показ пуш уведомлений
     * @type {function(): Promise<boolean>}
     */
    isPermissionsGranted;

    /**
     * Функция проверяющая отозваны ли права на показ пуш уведомлений
     * @type {function(): Promise<boolean>}
     */
    isPermissionsDenied;

    /**
     * Функция вызываемая при удалении токена.
     * Здесь должна находится логика которую нужно запустить,
     * при удалении токена для конкретной реализации сервиса (если такая логика есть).
     * @type {function(string): void}
     * @private
     */
    #onDeleteTokenCustomServiceLogic;

    /**
     * Конструктор абстрактного сервиса.
     * @param onStart - Функция с кастомной логикой вызываемая при запуске push сервиса
     * @param onStop - Функция с кастомной логикой вызываемая при остановке push сервиса
     * @param onIsPermissionsGranted - Функция проверяющая даны ли права на показ пуш уведомлений
     * @param onIsPermissionsDenied - Функция проверяющая отозваны ли права на показ пуш уведомлений
     * @param onCheckReadyState - Функция проверяющая готовы ли к работе сервиса
     *                            все доп. компоненты для конкретной реализации
     * @param onDeleteToken - Функция вызываемая при удалении токена с кастомной логикой для конкретной реализации
     */
    constructor({
        onStart,
        onStop,
        onIsPermissionsGranted,
        onIsPermissionsDenied,
        onCheckReadyState,
        onDeleteToken,
    }) {
        this.#onStart = onStart;
        this.#onStop = onStop;
        this.isPermissionsGranted = onIsPermissionsGranted;
        this.isPermissionsDenied = onIsPermissionsDenied;
        this.#onDeleteTokenCustomServiceLogic = onDeleteToken;

        this.checkReadyState = () => {
            const isAbstractServiceReady = [
                this.deleteTokenCallback,
                this.saveTokenCallback,
                this.#onStart,
                this.#onStop,
                this.isPermissionsGranted,
                this.isPermissionsDenied,
                this.#onDeleteTokenCustomServiceLogic,
            ].every(Boolean);

            const isCustomServiceReady = onCheckReadyState();

            if (isAbstractServiceReady && isCustomServiceReady) {
                this.isReady.resolve();
            }
        };
    }

    /**
     * Запускает сервис
     * @returns {Promise<void>}
     */
    start = async () => {
        try {
            if (this.#isRunning || !await this.isOk()) {
                return;
            }

            await this.#onStart();

            this.#isRunning = true;
        } catch (originalError) {
            console.error('Unable to start push API service', { originalError });
            /**
             * На мобильных браузерах формально права на пуши могут быть "default",
             * но если отключены пуши на уровне аппы, то будет выкинута ошибка.
             * Следует ее обработать и дать понять юзеру, что не помешало бы дать права.
             */
            if (originalError.code === 'messaging/permission-blocked') {
                throw originalError;
            }
        }
    }

    /**
     * Останавливает запущенный сервис
     * @returns {Promise<void>}
     */
    stop = async () => {
        try {
            if (!this.#isRunning) {
                return;
            }

            this.#deleteToken();

            await this.#onStop();

            this.#isRunning = false;
        } catch (originalError) {
            console.error('Unable to stop push API service', { originalError });
        }
    }

    /**
     * Регистрирует коллбэк, срабатывающий при удалении старого токена
     * @param {function(string): void} callback
     * @returns {void}
     */
    registerDeleteTokenCallback = (callback) => {
        this.deleteTokenCallback = (token) => {
            AppStorage.remove(FIREBASE_PUSH_API_TOKEN);
            callback(token);
        };

        this.checkReadyState();
    }

    /**
     * Регистрирует коллбэк, срабатывающий при сохранении нового токена
     * @param {function(string): void} callback
     * @returns {void}
     */
    registerSaveTokenCallback = (callback) => {
        this.saveTokenCallback = (token) => {
            AppStorage.set(FIREBASE_PUSH_API_TOKEN, token);
            callback(token);
        };

        this.checkReadyState();
    }

    /**
     * Сообщает сервису о наличии ошибки, мешающей запуску сервиса
     * @returns {void}
     */
    triggerError = () => {
        this.#hasError = true;
    }

    /**
     * Проверяет, готов ли сервис начать работу
     * @returns {Promise<boolean>}
     */
    isOk = async () => {
        try {
            await this.isReady;
            return !this.#hasError;
        } catch {
            return false;
        }
    }

    /**
     * Возвращает push token хранящийся в storage
     * @returns {string}
     */
    getTokenFromStorage = () => AppStorage.getOr(FIREBASE_PUSH_API_TOKEN, '');

    /**
     * Функция вызываемая при удалении токена.
     * Вызывает логику которую нужно прогнать для конкретной реализации сервиса,
     * затем вызывает общую логику при удалении токена (добавляется через registerDeleteTokenCallback)
     * @returns {void}
     */
    #deleteToken = () => {
        const token = this.getTokenFromStorage();
        if (!token) {
            return;
        }
        this.#onDeleteTokenCustomServiceLogic(token);
        this.deleteTokenCallback(token);
    }
}

export default PushApiController;
