export default (func) => {
    let isLoaded = false;
    let startLoading = false;
    let promise = null;

    return function oneLoading(...args) {
        if (isLoaded || startLoading) {
            return promise;
        }

        promise = (async () => {
            startLoading = true;

            try {
                const result = await func.call(this, ...args);
                isLoaded = true;

                return result;
            } catch (e) {
                startLoading = false;
                console.error('one loading:', (e || {}).message);

                throw e;
            }
        })();

        return promise;
    };
};
