// MOBILE
const applePhone = /iPhone/i;
const appleIpod = /iPod/i;
const appleTablet = /iPad/i;
const androidPhone = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i;
const androidTablet = /Android/i;
const amazonPhone = /(?=.*\bAndroid\b)(?=.*\bSD4930UR\b)/i;
const amazonTablet = /(?=.*\bAndroid\b)(?=.*\b(?:KFOT|KFTT|KFJWI|KFJWA|KFSOWI|KFTHWI|KFTHWA|KFAPWI|KFAPWA|KFARWI|KFASWI|KFSAWI|KFSAWA)\b)/i;
const windowsPhone = /Windows Phone/i;
const windowsTablet = /(?=.*\bWindows\b)(?=.*\bARM\b)/i;
const otherBlackberry = /BlackBerry/i;
const otherBlackberry10 = /BB10/i;
const otherOpera = /Opera Mini/i;
const otherChrome = /(CriOS|Chrome)(?=.*\bMobile\b)/i;
const otherFirefox = /(?=.*\bFirefox\b)(?=.*\bMobile\b)/i;
const otherFirefoxDesktop = /(?=.*\bFirefox\b)/i;
const otherYaBrowser = /(?=.*\bYaBrowser)(?=.*\bMobile\b)/i;
const sevenInch = new RegExp('(?:Nexus 7|BNTV250|Kindle Fire|Silk|GT-P1000)i');
const otherSafari = /^((?!chrome|android).)*safari/i;

// DESKTOP
const appleMac = /Macintosh/i;
const desktopMacOS = /Mac/i;
const desktopWindowsOS = /Win/i;
const desktopUnixOS = /X11/i;
const desktopLinuxOS = /Linux/i;

export const PlatformEnum = Object.freeze({
    PWA_IOS: 'pwa-ios',
    PWA_ANDROID: 'pwa-android',
    PWA_DESKTOP: 'pwa-desktop',
    ANDROID_NATIVE: 'android',
});

export const OsEnum = Object.freeze({
    IOS: 'ios',
    ANDROID: 'android',
    MACOS: 'macos',
    WINDOWS: 'windows',
    OTHER: 'other',
});

const match = (regex, userAgent) => regex.test(userAgent);

export const isPwa = () => (
    window.matchMedia('(display-mode: standalone)').matches
    || window.navigator.standalone
    || document.referrer.includes('android-app://')
);

const handleIntegratedBrowser = (userAgent) => {
    let ua = userAgent;
    // Facebook mobile app's integrated browser adds a bunch of strings that
    // match everything. Strip it out if it exists.
    let tmp = ua.split('[FBAN');
    if (typeof tmp[1] !== 'undefined') {
        [ua] = tmp;
    }

    // Twitter mobile app's integrated browser on iPad adds a "Twitter for
    // iPhone" string. Same probable happens on other tablet platforms.
    // This will confuse detection so strip it out if it exists.
    tmp = ua.split('Twitter');
    if (typeof tmp[1] !== 'undefined') {
        [ua] = tmp;
    }

    return ua;
};

export const parseMobileUserAgent = (userAgent) => {
    const ua = handleIntegratedBrowser(userAgent);

    const webviewChecker = document.querySelector('#webview-checker');
    const isPhoneTransformed = webviewChecker?.innerHTML.indexOf('tel:') > -1;

    return {
        android: {
            version: parseFloat(ua.slice(ua.indexOf('Android') + 8)) || null,
            phone: match(amazonPhone, ua) || match(androidPhone, ua),
            tablet: !match(amazonPhone, ua) && !match(androidPhone, ua)
                && (match(amazonTablet, ua) || match(androidTablet, ua)),
            get any() {
                return this.phone || this.tablet;
            },
        },
        apple: {
            version: (
                ((ua.includes('iPhone') || ua.includes('iPad')) && parseFloat(ua.substr(ua.indexOf('OS ') + 3, 3).replace('_', '.')))
                || null
            ),
            phone: match(applePhone, ua),
            ipod: match(appleIpod, ua),
            tablet: !match(applePhone, ua) && match(appleTablet, ua),
            get any() {
                return this.phone || this.ipod || this.tablet;
            },
            get webView() {
                return this.any && !isPhoneTransformed;
            },
        },
        amazon: {
            phone: match(amazonPhone, ua),
            tablet: !match(amazonPhone, ua) && match(amazonTablet, ua),
            get any() {
                return this.phone
                    || this.tablet;
            },
        },
        windows: {
            phone: match(windowsPhone, ua),
            tablet: match(windowsTablet, ua),
            get any() {
                return this.phone || this.tablet;
            },
        },
        other: {
            blackberry: match(otherBlackberry, ua),
            blackberry10: match(otherBlackberry10, ua),
            opera: match(otherOpera, ua),
            firefox: match(otherFirefox, ua),
            chrome: match(otherChrome, ua),
            yaBrowser: match(otherYaBrowser, ua),
            get any() {
                return this.blackberry
                    || this.blackberry10
                    || this.opera
                    || this.firefox
                    || this.chrome
                    || this.yaBrowser;
            },
            safari: match(otherSafari, ua),
        },
        sevenInch: {
            any: match(sevenInch, ua),
        },
        get any() {
            return this.apple.any
                || this.android.any
                || this.windows.any
                || this.other.any
                || this.sevenInch.any;
        },
    };
};

/**
 * @param userAgent {string}
 * @returns {object}
 */
export const parseUserAgent = (userAgent) => {
    const ua = handleIntegratedBrowser(userAgent);

    const mobile = parseMobileUserAgent(ua);

    const desktop = {
        apple: {
            mac: match(appleMac, ua),
            get any() {
                return this.mac;
            },
        },
        windows: {
            any: match(desktopWindowsOS, ua),
        },
        other: {
            safari: match(otherSafari, ua),
            firefox: match(otherFirefoxDesktop, ua),
        },
        get any() {
            return !mobile.any && (
                match(desktopMacOS, ua)
                || match(desktopWindowsOS, ua)
                || match(desktopUnixOS, ua)
                || match(desktopLinuxOS, ua)
            );
        },
    };

    const getOs = () => {
        if (mobile.apple.any) {
            return OsEnum.IOS;
        }

        if (mobile.android.any) {
            return OsEnum.ANDROID;
        }

        if (desktop.apple.mac) {
            return OsEnum.MACOS;
        }

        if (desktop.windows.any) {
            return OsEnum.WINDOWS;
        }

        return OsEnum.OTHER;
    };

    return {
        pwa: {
            any: isPwa(),
            get platform() {
                if (this.any) {
                    if (process.env.IS_MOBILE_APP) {
                        return PlatformEnum.ANDROID_NATIVE;
                    }

                    if (mobile.apple.any) {
                        return PlatformEnum.PWA_IOS;
                    }

                    if (mobile.android.any) {
                        return PlatformEnum.PWA_ANDROID;
                    }

                    if (desktop.any) {
                        return PlatformEnum.PWA_DESKTOP;
                    }
                }

                return null;
            },
        },
        mobile,
        desktop,
        isTouchDevice: process.env.VUE_APP_TARGET === 'client'
            && Boolean('ontouchstart' in window || navigator.msMaxTouchPoints),
        os: getOs(),
    };
};

export const devicePlugin = (userAgent) => ({
    install: (V) => {
        V.prototype.$platform = parseUserAgent(userAgent);
    },
});
