/**
 * Подготавливает переданный компонент для ленивой загрузки в зависимости от того, является ли он экземпляром Promise
 *
 * @param {object|Promise<object>} component
 * @param {object} loading
 * @param {number} delay
 * @returns {object|(function(): object)}
 */
const resolveComponent = (component, loading, delay) => {
    if (component instanceof Promise) {
        return () => ({
            component,
            loading,
            delay,
        });
    }

    return component;
};

/**
 * Возвращает загрузчик для компонента
 *
 * @param {object|Promise<object>|(function(): (object|Promise<object>))} viewOrFn
 * @param {object} [loader]
 * @param {number} [delay=300]
 * @returns {Promise<object>}
 */
export default async (viewOrFn, loader, delay = 300) => {
    const view = typeof viewOrFn === 'function' ? viewOrFn() : viewOrFn;
    const Component = resolveComponent(view, loader, delay);

    return {
        render() {
            return (
                <Component attrs={this.$attrs} on={this.$listeners}>
                    {this.$slots.default}
                </Component>
            );
        },
    };
};
