import PortalVue from 'portal-vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';

import {
    APP_MODE_AUTO,
    APP_MODE_DESKTOP,
    APP_MODE_MOBILE,
} from '@/constant/constants';
import { AppStorage, APP_MODE } from '@/plugins/AppStorage';
import ConsoleWrapper from '@/plugins/ConsoleWrapper';
import { isPwa, parseMobileUserAgent } from '@/plugins/Platform';
import loadPolyfills from '@/polyfills';
import '@/plugins/RegisterServiceWorker';
import '@/plugins/SavePartnerLinkToCache';
import '@/plugins/RegisterErudaConsole';

Vue.use(PortalVue);
Vue.use(VueRouter);
Vue.use(Vuex);

/**
 * @returns {number}
 */
const getCurrentMode = () => {
    if (window.location.search.includes('forceMobile=true')) {
        return APP_MODE_MOBILE;
    }

    if (window.location.search.includes('forceDesktop=true')) {
        return APP_MODE_DESKTOP;
    }

    return (
        isPwa()
            ? APP_MODE_AUTO
            : AppStorage.getOr(APP_MODE, APP_MODE_AUTO)
    );
};

/**
 * @returns {boolean}
 */
const isMobile = (userAgent) => parseMobileUserAgent(userAgent).any || window.screen.width < 600;

/**
 * @returns {Promise<{ createApp: Function }>}
 */
const loadDesktop = () => import(/* webpackChunkName: 'desktop' */'@desktop/index');

/**
 * @returns {Promise<{ createApp: Function }>}
 */
const loadMobile = () => import(/* webpackChunkName: 'mobile' */'@mobile/index');

/**
 * @type {Record<string, function(): Promise<{ createApp: Function }>>}
 */
const importers = {
    [APP_MODE_DESKTOP]: () => loadDesktop(),
    [APP_MODE_MOBILE]: () => loadMobile(),
    [APP_MODE_AUTO]: () => {
        const { userAgent } = navigator;
        const isMobileNow = isMobile(userAgent);

        window.addEventListener('resize', () => {
            if (userAgent !== navigator.userAgent && isMobileNow !== isMobile(navigator.userAgent)) {
                window.location.reload();
            }
        });

        return isMobileNow ? loadMobile() : loadDesktop();
    },
};

/**
 * @returns {Promise<Function>}
 */
export async function createApp() {
    try {
        await Promise.all([
            ConsoleWrapper(),
            loadPolyfills(),
        ]);
    } catch (err) {
        console.error('Application pre-configuration error', { err });
    }

    const { createApp: create } = await importers[getCurrentMode()]();
    return create();
}
