import {
    SOCIAL_LANGUAGES_AZ,
    SOCIAL_LANGUAGES_FR,
    SOCIAL_LANGUAGES_EN,
    SOCIAL_LANGUAGES_UZ,
    SOCIAL_LANGUAGES_IN,
    SOCIAL_LANGUAGES_RU,
} from '@/constant/constants';

/* Support */
const SUPPORT_PHONE_NUMBER = '88003017789';
const SUPPORT_PHONE_NUMBER_ADDITIONAL = '+74950888899';
/* -- Support -- */

/* Social Networks */
const telegramUrlRu = 'https://t.me/my_1win';
const vkUrlRu = 'https://vk.com/1winn';
const youtubeUrlRU = 'https://www.youtube.com/channel/UCbqqiExhQIE6kajlZmii9xA';

const telegramUrlIn = 'https://t.me/in_1win';
const facebookUrlIn = 'https://www.facebook.com/1winindia/';
const twitterUrlIn = 'https://twitter.com/1win_india';
const instagramUrlIn = 'https://www.instagram.com/1win_india';

const telegramUrlUz = 'https://t.me/uz_1w';
const facebookUrlUz = 'https://www.facebook.com/1winuz/';
const twitterUrlUz = 'https://twitter.com/1winuz';
const instagramUrlUz = 'https://www.instagram.com/1win_uz/';

const telegramUrlAz = 'https://t.me/az_1win';
const facebookUrlAz = 'https://www.facebook.com/1winazr';
const instagramUrlAz = 'https://www.instagram.com/1win_az/';

const telegramUrlFr = 'https://t.me/love_1win';
const facebookUrlFr = 'https://www.facebook.com/1winfr/';
const twitterUrlFr = 'https://twitter.com/1win_fr';
const instagramUrlFr = 'https://www.instagram.com/1win_fr/';

const telegramUrlEn = 'https://t.me/joinchat/bfhCv0orJDVjNmJi';
const facebookUrlEn = 'https://www.facebook.com/1win-English-103111365328235';
const twitterUrlEn = 'https://twitter.com/1winPro';
const instagramUrlEn = 'https://www.instagram.com/1win_en/';
/* -- Social Networks -- */

const config = Object.freeze({
    server: Object.freeze({
        host: process.env.SERVER_HOST,
        path: process.env.SOCKET_PATH,
        sportIconHost: process.env.SPORT_ICONS_HOST,
        proxyCdn: process.env.PROXY_CDN_URL,
        casinoImagesUrl: process.env.CASINO_IMAGES_CDN,
        tvImagesUrl: process.env.TV_IMAGES_CDN,
    }),

    phone: SUPPORT_PHONE_NUMBER,
    phoneAdditional: SUPPORT_PHONE_NUMBER_ADDITIONAL,
    // common.contacts.partners
    contacts: [
        {
            nameKey: 'support',
            email: 'support@1win.xyz',
        },
        {
            nameKey: 'security',
            email: 'security@1win.xyz',
        },
        {
            nameKey: 'payments',
            email: 'payments@1win.xyz',
        },
        {
            nameKey: 'business',
            email: 'business@1win.xyz',
        },
        {
            nameKey: 'partners',
            email: 'partner@1win.xyz',
        },
    ],

    // used only in templates
    partnerUrl: 'https://1w.run',

    vkUrl: vkUrlRu,
    youtubeUrl: youtubeUrlRU,

    telegramUrlByLang: Object.fromEntries([
        SOCIAL_LANGUAGES_AZ.map((lang) => [lang, telegramUrlAz]),
        SOCIAL_LANGUAGES_EN.map((lang) => [lang, telegramUrlEn]),
        SOCIAL_LANGUAGES_FR.map((lang) => [lang, telegramUrlFr]),
        SOCIAL_LANGUAGES_IN.map((lang) => [lang, telegramUrlIn]),
        SOCIAL_LANGUAGES_RU.map((lang) => [lang, telegramUrlRu]),
        SOCIAL_LANGUAGES_UZ.map((lang) => [lang, telegramUrlUz]),
    ].flat()),

    instagramUrlByLang: Object.fromEntries([
        SOCIAL_LANGUAGES_AZ.map((lang) => [lang, instagramUrlAz]),
        SOCIAL_LANGUAGES_EN.map((lang) => [lang, instagramUrlEn]),
        SOCIAL_LANGUAGES_FR.map((lang) => [lang, instagramUrlFr]),
        SOCIAL_LANGUAGES_IN.map((lang) => [lang, instagramUrlIn]),
        SOCIAL_LANGUAGES_UZ.map((lang) => [lang, instagramUrlUz]),
    ].flat()),

    facebookUrlByLang: Object.fromEntries([
        SOCIAL_LANGUAGES_AZ.map((lang) => [lang, facebookUrlAz]),
        SOCIAL_LANGUAGES_EN.map((lang) => [lang, facebookUrlEn]),
        SOCIAL_LANGUAGES_FR.map((lang) => [lang, facebookUrlFr]),
        SOCIAL_LANGUAGES_IN.map((lang) => [lang, facebookUrlIn]),
        SOCIAL_LANGUAGES_UZ.map((lang) => [lang, facebookUrlUz]),
    ].flat()),

    twitterUrlByLang: Object.fromEntries([
        SOCIAL_LANGUAGES_EN.map((lang) => [lang, twitterUrlEn]),
        SOCIAL_LANGUAGES_FR.map((lang) => [lang, twitterUrlFr]),
        SOCIAL_LANGUAGES_IN.map((lang) => [lang, twitterUrlIn]),
        SOCIAL_LANGUAGES_UZ.map((lang) => [lang, twitterUrlUz]),
    ].flat()),

    oauth: Object.freeze({
        oauthDomain: process.env.SOCIAL_AUTH_DOMAIN,
        oauthTelegramDomain: process.env.SOCIAL_AUTH_TELEGRAM_DOMAIN,
        // staging social webs credentials: Sergei Nosov
        vkClientId: process.env.SOCIAL_AUTH_VK_CLIENT_ID,
        googleClientId: process.env.SOCIAL_AUTH_GOOGLE_CLIENT_ID,
        okClientId: process.env.SOCIAL_AUTH_OK_CLIENT_ID,
        // staging social webs credentials: Ilya Nurullin
        facebookClientId: process.env.SOCIAL_AUTH_FACEBOOK_CLIENT_ID,
        telegramBotId: process.env.SOCIAL_AUTH_TELEGRAM_BOT_ID,
        mailClientId: process.env.SOCIAL_AUTH_MAIL_CLIENT_ID,
        yandexClientId: process.env.SOCIAL_AUTH_YANDEX_CLIENT_ID,
    }),
    get staticUrl() {
        return (process.env.IS_MOBILE_APP ? process.env.CDN_URL : __webpack_public_path__).replace(/\/$/, '');
    },
});

const { oauth } = config;

export const VK_OAUTH_REDIRECT_URI = `${oauth.oauthDomain}/oauth/vk`;

export const getVkConnectUrl = (state) => `https://oauth.vk.com/authorize?client_id=${oauth.vkClientId}&v=5.78&scope=offline&display=page&state=${state}&redirect_uri=${VK_OAUTH_REDIRECT_URI}`;
export const getOkUrl = (state) => `https://connect.ok.ru/oauth/authorize?client_id=${oauth.okClientId}&scope=GET_EMAIL&response_type=code&state=${state}&redirect_uri=${oauth.oauthDomain}/oauth/ok`;
export const getGoogleUrl = (state) => `https://accounts.google.com/o/oauth2/v2/auth?client_id=${oauth.googleClientId}&access_type=offline&response_type=code&scope=https://www.googleapis.com/auth/userinfo.profile+https://www.googleapis.com/auth/userinfo.email&state=${state}&redirect_uri=${oauth.oauthDomain}/oauth/google`;

export default config;
