import AffiliateHook from '@/plugins/AffiliateHook';
import { paramsToQuery } from '@/plugins/Custom';

const SUB_IDS = new Array(5).fill('sub').map((_, i) => `${_}${i + 1}`);

export default () => {
    const {
        partner_key: partnerKey,
        sub_ids: subIds,
    } = AffiliateHook.getPartner();

    if (!subIds) return undefined;

    const hash = partnerKey;
    const subIdsMap = subIds
        .split('&')
        .map((sub) => sub.split('='))
        .filter(([key, value]) => SUB_IDS.includes(key) && value)
        .reduce((obj, [key, value]) => ({
            ...obj,
            [key]: value,
        }), {});
    const query = paramsToQuery(subIdsMap);

    return `${window.location.origin}${window.location.pathname}${query ? `?${query}` : ''}${hash ? `#${hash}` : ''}`;
};
