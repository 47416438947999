const betsIntProps = ['sport', 'category', 'tournament', 'match', 'sportId', 'categoryId', 'tournamentId', 'matchId'];
const betsStringProps = ['service'];

/**
 * @param {object} params
 * @returns {object}
 */
export function getBetsProps(params) {
    const props = {};

    for (const [key, value] of Object.entries(params)) {
        if (betsIntProps.includes(key) && value > 0) {
            props[key] = Number.parseInt(value, 10);
        } else if (betsStringProps.includes(key)) {
            props[key] = value;
        }
    }

    return props;
}

/**
 * @param {string[]} components
 * @returns {object}
 */
export function getBetsComponentsProps(
    getPropsFromParams = () => ({}),
    components = ['default', 'left'],
) {
    return components.reduce((componentProps, current) => ({
        ...componentProps,
        [current]: ({ params }) => ({
            ...params,
            ...getBetsProps(params),
            ...getPropsFromParams(params, current),
        }),
    }), {});
}
