import { parseToRgb } from './parseToRgb';

/**
 * @param {RgbColor|number} value
 * @param {number} green
 * @param {number} blue
 * @returns {string}
 */
export function rgb(value, green, blue) {
    if (typeof value === 'number' && typeof green === 'number' && typeof blue === 'number') {
        return `rgb(${value},${green},${blue})`;
    }

    if (typeof value === 'object' && green === undefined && blue === undefined) {
        return `rgb(${value.red},${value.green},${value.blue})`;
    }

    return undefined;
}

/**
 * @param {RgbColor|number|string} firstValue
 * @param {number?} secondValue
 * @param {number?} thirdValue
 * @param {number?} fourthValue
 * @returns {string}
 */
export function rgba(firstValue, secondValue, thirdValue, fourthValue) {
    if (typeof firstValue === 'string' && typeof secondValue === 'number') {
        const rgbValue = parseToRgb(firstValue);
        return `rgba(${rgbValue.red},${rgbValue.green},${rgbValue.blue},${secondValue})`;
    }

    if (
        typeof firstValue === 'number'
        && typeof secondValue === 'number'
        && typeof thirdValue === 'number'
        && typeof fourthValue === 'number'
    ) {
        return fourthValue >= 1
            ? rgb(firstValue, secondValue, thirdValue)
            : `rgba(${firstValue},${secondValue},${thirdValue},${fourthValue})`;
    }

    if (
        typeof firstValue === 'object'
        && secondValue === undefined
        && thirdValue === undefined
        && fourthValue === undefined
    ) {
        return firstValue.alpha >= 1
            ? rgb(firstValue.red, firstValue.green, firstValue.blue)
            : `rgba(${firstValue.red},${firstValue.green},${firstValue.blue},${firstValue.alpha})`;
    }

    return undefined;
}

/**
 * @param {number|string} weight
 * @param {string} color
 * @param {string} otherColor
 * @returns {string}
 */
export function mix(weight, color, otherColor) {
    if (color === 'transparent') {
        return otherColor;
    }

    if (otherColor === 'transparent') {
        return color;
    }

    if (weight === 0) {
        return otherColor;
    }

    const parsedColor1 = parseToRgb(color);

    const color1 = {
        ...parsedColor1,
        alpha: typeof parsedColor1.alpha === 'number' ? parsedColor1.alpha : 1,
    };

    const parsedColor2 = parseToRgb(otherColor);

    const color2 = {
        ...parsedColor2,
        alpha: typeof parsedColor2.alpha === 'number' ? parsedColor2.alpha : 1,
    };

    // The formula is copied from the original Sass implementation:
    // http://sass-lang.com/documentation/Sass/Script/Functions.html#mix-instance_method
    const alphaDelta = color1.alpha - color2.alpha;
    const x = Number.parseFloat(weight) * 2 - 1;
    const y = x * alphaDelta === -1 ? x : x + alphaDelta;
    const z = 1 + x * alphaDelta;
    const weight1 = (y / z + 1) / 2.0;
    const weight2 = 1 - weight1;

    const mixedColor = {
        red: Math.floor(color1.red * weight1 + color2.red * weight2),
        green: Math.floor(color1.green * weight1 + color2.green * weight2),
        blue: Math.floor(color1.blue * weight1 + color2.blue * weight2),
        alpha: color1.alpha * Number.parseFloat(weight) + color2.alpha * (1 - Number.parseFloat(weight)),
    };

    return rgba(mixedColor);
}

export { parseToRgb } from './parseToRgb';
