import pushApiService from '@/services/PushApiService';

import ServiceWorker from '@publicJs/sw';

if (!process.env.IS_MOBILE_APP && 'serviceWorker' in navigator) {
    navigator.serviceWorker.register(ServiceWorker, {
        scope: '/',
    })
        .catch((e) => {
            console.log('Main service worker registration failed.', e);
            return 'error';
        })
        .then((r) => {
            if (r === 'error') {
                pushApiService.controller.triggerError();
                return;
            }

            pushApiService.controller.registerServiceWorker?.(r);
            console.log('Service worker registered.');
        })
        .catch((e) => {
            pushApiService.controller.triggerError();
            console.log('PushApiService Service worker registration failed.', e);
        });
}
