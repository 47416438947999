/**
 * @typedef {object} RgbColor
 * @property {number} red
 * @property {number} green
 * @property {number} blue
 */

/**
 * @typedef {object} RgbaColor
 * @property {number} red
 * @property {number} green
 * @property {number} blue
 * @property {number} alpha
 */

const hexRegex = /^#[a-fA-F0-9]{6}$/;
const hexRgbaRegex = /^#[a-fA-F0-9]{8}$/;
const reducedHexRegex = /^#[a-fA-F0-9]{3}$/;
const reducedRgbaHexRegex = /^#[a-fA-F0-9]{4}$/;
const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/i;
const rgbaRegex = /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*([-+]?[0-9]*[.]?[0-9]+)\s*\)$/i;

/**
 * @param {string} color
 * @returns {RgbColor|RgbaColor}
 * Returns an RgbColor or RgbaColor object. This utility function is only useful
 * if want to extract a color component. With the color util `toColorString` you
 * can convert a RgbColor or RgbaColor object back to a string.
 *
 * @example
 * // Assigns `{ red: 255, green: 0, blue: 0 }` to color1
 * const color1 = parseToRgb('rgb(255, 0, 0)');
 * // Assigns `{ red: 92, green: 102, blue: 112, alpha: 0.75 }` to color2
 * const color2 = parseToRgb('hsla(210, 10%, 40%, 0.75)');
 */
export function parseToRgb(color) {
    if (color.match(hexRegex)) {
        return {
            red: parseInt(`${color[1]}${color[2]}`, 16),
            green: parseInt(`${color[3]}${color[4]}`, 16),
            blue: parseInt(`${color[5]}${color[6]}`, 16),
        };
    }

    if (color.match(hexRgbaRegex)) {
        const alpha = parseFloat(
            (parseInt(`${color[7]}${color[8]}`, 16) / 255).toFixed(2),
        );
        return {
            red: parseInt(`${color[1]}${color[2]}`, 16),
            green: parseInt(`${color[3]}${color[4]}`, 16),
            blue: parseInt(`${color[5]}${color[6]}`, 16),
            alpha,
        };
    }

    if (color.match(reducedHexRegex)) {
        return {
            red: parseInt(`${color[1]}${color[1]}`, 16),
            green: parseInt(`${color[2]}${color[2]}`, 16),
            blue: parseInt(`${color[3]}${color[3]}`, 16),
        };
    }

    if (color.match(reducedRgbaHexRegex)) {
        const alpha = parseFloat(
            (parseInt(`${color[4]}${color[4]}`, 16) / 255).toFixed(2),
        );
        return {
            red: parseInt(`${color[1]}${color[1]}`, 16),
            green: parseInt(`${color[2]}${color[2]}`, 16),
            blue: parseInt(`${color[3]}${color[3]}`, 16),
            alpha,
        };
    }

    const rgbMatched = rgbRegex.exec(color);
    if (rgbMatched) {
        return {
            red: parseInt(`${rgbMatched[1]}`, 10),
            green: parseInt(`${rgbMatched[2]}`, 10),
            blue: parseInt(`${rgbMatched[3]}`, 10),
        };
    }

    const rgbaMatched = rgbaRegex.exec(color.substring(0, 50));
    if (rgbaMatched) {
        return {
            red: parseInt(`${rgbaMatched[1]}`, 10),
            green: parseInt(`${rgbaMatched[2]}`, 10),
            blue: parseInt(`${rgbaMatched[3]}`, 10),
            alpha: parseFloat(`${rgbaMatched[4]}`),
        };
    }

    return undefined;
}
