import {
    REG_FULL,
    REG_MOBILE,
    REG_ONE_CLICK,
    REG_SOCIAL,
} from '@/constant/registrationTypes';
import { CASINO_CATEGORIES } from '@/store/casino/enums';

import ROUTE_NAME_MOBILE from '@mobile/constant/routeName';

export const IS_VIP_DOMAIN = window.vipDomain === true;

export const AUTH_COOKIE_NAME = '1w_token';
export const LOCALE_COOKIE_NAME = '1w_locale';

export const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY; // eslint-disable-line

export const { LangItemsEnum } = window.oneWinLang;

export const {
    LANG_RU, LANG_EN, LANG_UA,
    LANG_UZ, LANG_TR, LANG_ZA,
    LANG_IN, LANG_DE, LANG_ES,
    LANG_FR, LANG_ID, LANG_IT,
    LANG_PL, LANG_PT, LANG_KG,
    LANG_TJ, LANG_MD, LANG_AZ,
    LANG_KZ,
} = LangItemsEnum.enumKeyToOneWinKeyMap;

export const SNG_COUNTRIES_LANGUAGES = [
    LANG_RU, LANG_UA, LANG_UZ,
    LANG_MD, LANG_KG, LANG_TJ,
    LANG_AZ,
];

export const SOCIAL_LANGUAGES_EN = [
    LANG_EN, LANG_TR, LANG_DE,
    LANG_ES, LANG_ID, LANG_IT,
    LANG_PL, LANG_PT, LANG_KG,
    LANG_TJ, LANG_MD,
];
export const SOCIAL_LANGUAGES_IN = [LANG_IN];
export const SOCIAL_LANGUAGES_UZ = [LANG_UZ];
export const SOCIAL_LANGUAGES_AZ = [LANG_AZ];
export const SOCIAL_LANGUAGES_FR = [LANG_FR, LANG_ZA];
export const SOCIAL_LANGUAGES_RU = [LANG_RU, LANG_UA, LANG_KZ];

export const VK_COUNTRIES_LANGUAGES = SNG_COUNTRIES_LANGUAGES.filter((lang) => lang !== LANG_UA);

export const getDefaultEn = (lang) => ([LANG_EN, LANG_RU].includes(lang) ? lang : LANG_EN);
export const LANG_ALLOWED_TV = [LANG_RU];
export const LANG_ALLOWED_INVEST = [LANG_RU];

export const API_TV_URL = 'https://hls1.1win.tv';

export const TV_DESKTOP_NAMES_ROUTE = ['tv', 'tv-soon', 'tv-new'];
export const TV_MOBILE_NAMES_ROUTE = [
    ROUTE_NAME_MOBILE.TV,
    ROUTE_NAME_MOBILE.TV_COMING_SOON,
    ROUTE_NAME_MOBILE.TV_NEW_EPISODES,
];

export const [
    APP_MODE_AUTO,
    APP_MODE_DESKTOP,
    APP_MODE_MOBILE,
] = [0, 1, 2];

export const [HOME] = ['home'];

export const [PLATFORM_ANDROID, PLATFORM_IOS] = ['android', 'ios'];

export const [GAME_CASINO, GAME_CASINO_LIVE] = ['casino-all', 'casino-live'];

export const CASINO_MULTI_CURRENCY_ERROR = 'common.errors.global.currencyNotSupported';

export const [SERVICE_LIVE, SERVICE_PREMATCH, BETS_RESULT] = ['live', 'prematch', 'bets-result'];
export const SERVICE_LIST = [SERVICE_LIVE, SERVICE_PREMATCH];
export const SERVICE_VALIDATOR = (val) => SERVICE_LIST.includes(val);

export const BET_TYPE_ORDINARY = 'ordinary';
export const BET_TYPE_SERIES = 'series';
export const BET_TYPE_EXPRESS = 'express';

export const CONTENT_TYPE_MAIN = 'main';
export const CONTENT_TYPE_POKER = 'poker';
export const CONTENT_TYPE_BETS_ONLY_NO_DEPOSIT = 'bets-only-no-deposit';
export const CONTENT_TYPE_BETS_ONLY_WITHOUT_ALL = 'only-bets-without-all';
export const CONTENT_TYPE_PREFER_CASINO = 'prefer-casino';
export const CONTENT_TYPE_INVEST = 'invest';
export const CONTENT_TYPE_MAIN_NO_TV = 'main-notv';

export const PROVIDERS_ALL_LIST = [0, 1, 2, 3, 5, 6, 8, 15];
export const [
    PROVIDER_BETRADAR,
    PROVIDER_XBET,
    PROVIDER_365BET,
    PROVIDER_1WIN,
    PROVIDER_BETINVEST,
    PROVIDER_BETRADAR_PARSER,
    PROVIDER_ODDSGG,
    PROVIDER_TEST,
] = PROVIDERS_ALL_LIST;
export const PROVIDERS_ALL_SET = new Set(PROVIDERS_ALL_LIST);
export const PROVIDER_PARSER_LIST = [
    PROVIDER_XBET,
    PROVIDER_365BET,
    PROVIDER_BETINVEST,
    PROVIDER_ODDSGG,
    PROVIDER_BETRADAR_PARSER,
    PROVIDER_TEST,
];
export const PROVIDERS = {
    PROVIDER_XBET,
    PROVIDER_BETRADAR,
};

export const [TVBET_GAME, TVBET_POKER, BETGAME_TV, TWAINSPORT_TV] = [0, 1, 2, 3];
export const TVBET_POKER_EVENT_OPEN_DEPOSIT = 'evenbet:depositClick';

export const BET_STATUS_OPENED = 0;
export const BET_STATUS_LOSE = 1;
export const BET_STATUS_WIN = 2;
export const BET_STATUS_REFUND = 3;
export const BET_STATUS_SOLD = 4;

export const DEPOSIT_STATUS_WAITING = 0;
export const DEPOSIT_STATUS_SUCCESS = 1;
export const DEPOSIT_STATUS_REJECTED = 2;
export const DEPOSIT_STATUS_PARTNER_SUCCESS = 3;
export const DEPOSIT_STATUS_PARTNER_REJECTED = 4;

export const RESUME_STATUS_INVESTMENT = 'investment';

export const BETS_TAB_EVENTS = 'events';
export const BETS_TAB_SPORTS = 'sports';

export const CASE_TYPE_CLASSIC = 'classic';
export const CASE_TYPE_IMAGE = 'image';
export const CASE_TYPE_LIST = Object.freeze([CASE_TYPE_CLASSIC, CASE_TYPE_IMAGE]);

export const CASES_THEME_CLASSIC = 'classic';
export const CASES_THEME_MEMES = 'memes';
export const CASES_THEME_UFC = 'ufc';
export const CASES_THEME_YOUTUBE = 'youtube';
export const CASES_THEME_LIST = Object.freeze([
    CASES_THEME_CLASSIC,
    CASES_THEME_MEMES,
    CASES_THEME_UFC,
    CASES_THEME_YOUTUBE,
]);
export const CASES_THEME_LIST_EXCLUDED_OUTSIDE_SNG_COUNTRIES = [CASES_THEME_MEMES, CASES_THEME_YOUTUBE];

export const CASES_THEME_TYPE_MAP = {
    [CASES_THEME_CLASSIC]: CASE_TYPE_CLASSIC,
    [CASES_THEME_MEMES]: CASE_TYPE_IMAGE,
    [CASES_THEME_UFC]: CASE_TYPE_IMAGE,
    [CASES_THEME_YOUTUBE]: CASE_TYPE_IMAGE,
};

export const CASE_COLORS_NAME = ['silver', 'bronze', 'green', 'cyan', 'blue', 'gold', 'pink', 'red'];

export const CASE_TYPE_ID_MAP = {
    0: {
        name: 'top',
        color: CASE_COLORS_NAME[2],
    },
    1: {
        name: 'lucky',
        color: CASE_COLORS_NAME[1],
    },
    2: {
        name: 'dandy',
        color: CASE_COLORS_NAME[3],
    },
    3: {
        name: 'luxury',
        color: CASE_COLORS_NAME[4],
    },
    4: {
        name: 'newbie',
        color: CASE_COLORS_NAME[0],
    },
    5: {
        name: 'oligarch',
        color: CASE_COLORS_NAME[5],
    },
    6: {
        name: 'millionaire',
        color: CASE_COLORS_NAME[6],
    },
    7: {
        name: 'master',
        color: CASE_COLORS_NAME[7],
    },
};

export const CASE_COLOR_MAP = Object.freeze({
    [CASE_COLORS_NAME[4]]: ['#41A9FF', '#0064BF'],
    [CASE_COLORS_NAME[1]]: ['#EDD292', '#874028'],
    [CASE_COLORS_NAME[3]]: ['#6CDDFF', '#00A3A2'],
    [CASE_COLORS_NAME[5]]: ['#F2ED4A', '#E66D00'],
    [CASE_COLORS_NAME[2]]: ['#90FF4F', '#18801D'],
    [CASE_COLORS_NAME[6]]: ['#F055D7', '#E6254F'],
    [CASE_COLORS_NAME[7]]: ['#FF8937', '#FF2958'],
    [CASE_COLORS_NAME[0]]: ['#C4C4C4', '#4A4A4A'],
});

export const CASES_ID_NAME_MAP = {
    0: 'top',
    1: 'lucky',
    2: 'dandy',
    3: 'luxury',
    4: 'newbie',
    5: 'oligarch',
    6: 'millionaire',
    7: 'master',
};

export const WALLER_STATUS_AWAIT = 0;
export const WALLER_STATUS_SUCCESS = 1;
export const WALLER_STATUS_REJECT = 2;

export const TAB_COUPON_KEY = 'coupon';
export const TAB_OPEN_BETS_KEY = 'open-bets';

export const REGISTRATION_TAB_ONE_CLICK = 'one-click';

export const WIDGET_ID_MATCH_TIMER = 1;

export const UserIntention = {
    OPEN_CASINO_GAME: 'open-casino-game',
};

export const LIST_SPORT_WITCH_COUNTRY = [
    'aussie_rules',
    'badminton',
    'bandy',
    'baseball',
    'basketball_3x3',
    'basketball',
    'beach_soccer',
    'beach_volley',
    'darts',
    'field_hockey',
    'floorball',
    'football',
    'futsal',
    'gaelic_football',
    'handball',
    'ice_hockey',
    'lacrosse',
    'og_nba2',
    'soccer',
    'speedway',
    'squash',
    'table_tennis',
    'volleyball',
    'waterpolo',
];

export const WS_TO_HTTP_REDIRECTS = {
    'MATCH-STORAGE-3:sport-all': 'MATCH-STORAGE-3:sport-all',
    'MATCH-STORAGE-3:sport-categories': 'MATCH-STORAGE-3:sport-categories',
    'MATCH-STORAGE-3:sport-tournaments': 'MATCH-STORAGE-3:sport-tournaments',
    'MATCH-STORAGE-3:tournament-matches': 'MATCH-STORAGE-3:tournament-matches',
    'MATCH-STORAGE-3:category-tournaments': 'MATCH-STORAGE-3:category-tournaments',
    'MATCH-STORAGE-3:match-odds': 'MATCH-STORAGE-3:match-odds',
    'RESULT:results-all': 'RESULT:results-all',

    'USER:auth-register': 'USER:auth-register',
    'USER:auth-login': 'USER:auth-login',
    'USER:auth-logout': 'USER:auth-logout',

    // 'RESULT:sport-all': 'RESULT:sport-all',
    // 'RESULT:search-all': 'RESULT:search-all',

    // BANKING
    // 'BANKING:balance-get': 'BANKING:balance-get',
    // 'BANKING:withdrawal-create': 'BANKING:withdrawal-create',
    // 'BANKING:withdrawal-confirm': 'BANKING:withdrawal-confirm',
    // 'BANKING:withdrawal-get': 'BANKING:withdrawal-get',
    // 'BANKING:withdrawal-history': 'BANKING:withdrawal-history',
    // 'BANKING:deposit-create': 'BANKING:deposit-create',
};

export const CANCELABLE_REQUEST = [
    'MATCH-STORAGE-3:sport-all',
    'MATCH-STORAGE-3:sport-categories',
    'MATCH-STORAGE-3:sport-tournaments',
    'MATCH-STORAGE-3:tournament-matches',
    'MATCH-STORAGE-3:category-tournaments',
    'MATCH-STORAGE-3:match-odds',
    'MATCH-STORAGE-3:sport-top-tournaments',
    'MATCH-STORAGE-3:match-hot',
    'MATCH-STORAGE-3:match-one',
    'MATCH-TRANSLATIONS:translations',
    'RESULT:results-all',
    'EVENTS-SEARCH:events-search',
];

export const LICENCE_TEXT = '1win.pro operated by 1WIN N.V. which is registered at Dr. H. Fergusonweg 1, Curaçao, with company number 147039, and having gaming license 8048/JAZ2018-040 and all rights to operate the gaming software. MFI INVESTMENTS LIMITED, a company, whose registered office is at 3, Chytron Street, Flat/Office 301, P.C. 1075 Nicosia, Cyprus with company number HE386738.EU company MFI Investments Ltd is providing payment services as an agent according to the license agreement concluded between MFI INVESTMENTS LIMITED and 1WIN N.V.';

export const DOCUMENT_TYPES = Object.freeze({
    passport: 1,
});

export const VERIFICATION_STATUS_NOT_NEED = 0;
export const VERIFICATION_STATUS_NEED = 1;
export const VERIFICATION_STATUS_WAITING = 2;
export const VERIFICATION_STATUS_SUCCESS = 3;

export const GUARD_VERIFY = 'verify';
export const GUARD_SHOW_VERIFY = 'show-verify';

export const TYPE_FORM_REMEMBER = 'remember';

export const NOTIFY_POSITION = {
    DEFAULT: 'default',
    TOP: 'top',
};

export const REMOVE_LAYOUT_MARGIN = Symbol('Remove layout margin');

export const REMOVE_LAYOUT_MARGIN_BOTTOM = Symbol('Remove layout margin');

export const SOCKET_SERVICE = {
    BETS: process.env.SERVICE_BETS,
    MATCH_STORAGE: process.env.SERVICE_MATCH_STORAGE,
    BANKING: process.env.SERVICE_BANKING,
};

export const [
    WITHDRAWAL_SPECIFY_PROFILE_INFO,
    WITHDRAWAL_CREATE,
    WITHDRAWAL_CONFIRMATION,
    WITHDRAWAL_BLOCKED,
    WITHDRAWAL_DISABLED,
] = [0, 1, 2, 3, 4];

export const PAYMENT_SYSTEM_1WIN_CASH = '1win_cash';
export const PAYMENT_SYSTEM_CASHBACK = 'cashback';
export const PAYMENT_SYSTEM_1WINVEST = '1winvest';

export const PAYMENT_P2P = 'card-1win-p2p';
export const PAYMENT_PHONEPE_ONEWIN = 'phonepe_onewin';

// если иконка содержит градиент,
// то стоит перенeсти его в компонент '@desktop/assets/icons/payments/PaymentsSvgGradients.vue'
// для корректной работы градиента в Safari
export const PAYMENT_ICON_MAP = {
    [PAYMENT_SYSTEM_1WIN_CASH]: 'cash',
    advcash_rub: 'advcash',
    advcash_wallet: 'advcash',
    airtm: 'airtm',
    atf24: 'atf24',
    beeline_rub: 'beeline',
    btc_usd: 'btc',
    card: 'card',
    card_azn: 'card',
    card_rub: 'card',
    card_full: 'card',
    card_usd: 'card',
    card_btc: 'card',
    card_short: 'card',
    card_method: 'card',
    card_visa: 'visa',
    card_mastercard: 'mastercard',
    card_mir: 'mir',
    cash: 'cash',
    cashtocode: 'cashtocode',
    cepbank: 'cepbank',
    eth_usd: 'ethereum',
    globepay: 'globepay',
    hizliqr: 'hizliqr',
    jetonWalletV3: 'jetonWalletV3',
    kassa24: 'kassa24',
    megafon_rub: 'megafon',
    mobile: 'mobile',
    mobile_with_email: 'mobile',
    mobile_with_name: 'mobile',
    mts_rub: 'mts',
    'online-indonesian-banks': 'online-indonesian-banks',
    'online-malaysian-banks': 'online-malaysian-banks',
    'online-thailand-banks': 'online-thailand-banks',
    'online-vietnam-banks': 'online-vietnam-banks',
    'papara-wallet': 'papara-wallet',
    payeer_rub: 'payeer',
    piastrix_rub: 'piastrix',
    payplus: 'payplus',
    rechcompt: 'rechcompt',
    qiwi_rub: 'qiwi',
    qiwi_eur: 'qiwi',
    qiwi_usd: 'qiwi',
    tele2_rub: 'tele2',
    tether: 'tether',
    webmoney_usd: 'webmoney',
    yamoney_rub: 'yandex',
    kyivstar_uah: 'kyivstar',
    lifecell_uah: 'lifecell',
    vodafone_uah: 'vodafone',
    intertelecom_uah: 'intertelecom',
    uzcard: 'uzcard',
    umob: 'u-mob',
    apple_pay: 'apple-pay',
    perfectmoney: 'perfect-money',
    china_union_pay: 'china-union-pay',
    rbk_bank_kz: 'rbk-bank-kz',
    'online-brazil-banks': 'brazil-online-banking',
    oxxo: 'oxxo',
    eco_voucher: 'eco-voucher',
    'alfa-click': 'alfa-click',
    homebank: 'homebank',
    rapipago: 'rapipago',
    pago: 'pago',
    boleto: 'boleto',
    'online-mexico-banks': 'mexico-online-banking',
    'online-argentina-banks': 'argentina-online-banking',
    'payturka-qr': 'cmt-cuzdan',
    'payturka-cmt': 'cmt-cuzdan',
    fixturka: 'fixturka',
    [PAYMENT_SYSTEM_CASHBACK]: 'partner',
    advcash: 'advcash',
    loterica: 'loterica',
    'carga-virtual': 'cargavirtual',
    link: 'link',
    'online-india-banks': 'online-india-banks',
    'online-philippines-banks': 'banks-of-the-philippines',
    'online-thailand-qr-banks': 'online-thailand-qr-banks',
    pay_wafe: 'pay-wafe',
    paytm: 'paytm',
    paytm_onewin: 'paytm-onewin',
    paytm_gate2way: 'paytm-gate2way',
    upi: 'upi',
    upi_onewin: 'upi-onewin',
    upi_full: 'upi',
    upi_gate2way: 'upi',
    imps: 'imps',
    imps_gate2way: 'imps',
    phone_pe: 'phone-pe',
    [PAYMENT_PHONEPE_ONEWIN]: 'phone-pe-onewin',
    vietcombank: 'vietcombank',
    bkash: 'b-kash',
    bkash_onewin: 'bkash-onewin',
    provincianet: 'provincianet',
    mpesa: 'm-pesa',
    gpay_icici: 'gpay',
    gpay_onewin: 'gpay-onewin',
    gpay_gate2way: 'gpay',
    nganluong_email: 'nganluong',
    fk_wallet: 'fk-wallet',
    snapscan: 'snapscan',
    ozow: 'ozow',
    african_bank_payment_ussd: 'african_bank',
    african_bank_payment_qr: 'african_bank',
    mybux_voucher: 'mybux_voucher',
    muchbetter: 'muchbetter',
    phonepe: 'phonepe',
    phonepe_gate2way: 'phonepe-gate2way',
    airtel: 'airtel',
    mobikwik: 'mobikwik',
    ecopayz: 'ecopayz-in-out',
    cashu: 'e-wallet-cashu',
    momo_qr: 'momopay',
    zalo_qr: 'zalopay',
    qiwi_kzt: 'qiwi',
    qiwi_kzt_cash_in: 'qiwi',
    nbi: 'nbi',
    rupay: 'rupay',
    imps_paywize: 'imps',
    world_ep: 'world_ep',
    clapay: 'clapay',
    monetix: 'monetix',
    bhim_onewin: 'bhim-onewin',
    bank_transfer_onewin: 'bank-transfer-onewin',
    [PAYMENT_P2P]: 'p2p',
    'card-p2p': 'p2p',
    humo_card: 'humo_card',
    reliance_jio: 'reliance_jio',
    ola_money: 'ola_money',
    freecharge: 'freecharge',
    webmoney_uah: 'webmoney_uah',
    'orange-burkina': 'orange-burkina',
    'moov-burkina': 'moov-burkina',
    litecoin: 'litecoin',
    monero: 'monero',
    bitcoin_cash: 'bitcoin_cash',
    dash: 'dash',
    neo: 'neo',
    cardano: 'cardano',
    doge: 'doge',
    zcash: 'zcash',
    ripple: 'ripple',
    nem: 'nem',
    stellar: 'stellar',
    eos: 'eos',
    binance: 'binance',
    tron: 'tron',
    usdc: 'usdc',
    btc: 'btc',
    eth: 'ethereum',
    money_go: 'money_go',
    money_go_voucher: 'money_go',
    'express-havale': 'express-havale',
    'vip-havale': 'vip-havale',
    mft: 'mft',
    peple: 'peple',
    secpay: 'secpay',
    interac: 'interac',
    'interac-ach': 'interac-ach',
    'interac-eto': 'interac-eto',
    'online-mexico-wallets': 'online-mexico-wallets',
    'online-colombian-wallets': 'online-colombian-wallets',
    'online-colombian-banks': 'online-colombian-wallets',
    'online-peruvian-banks': 'online-peruvian-banks',
    'online-nigerian-banks': 'online-nigerian-banks',
    spei: 'spei',
    ouipay: 'ouipay',
    pix: 'pix',
    american_express: 'american_express',
    diners_club: 'diners_club',
    carnet_credito: 'carnet',
    lider: 'lider',
    magna: 'magna',
    hipercard: 'hipercard',
    neosurf: 'neosurf',
    fawry: 'fawry',
    bancomer_voucher: 'bancomer',
    efecty_voucher: 'efecty',
    safetypay_voucher: 'safetypay',
    trustly: 'trustly',
    volt: 'volt',
    'online-uk-banks': 'online-uk-banks',
    'online-france-banks': 'online-france-banks',
    'online-norway-banks': 'online-norway-banks',
    'online-denmark-banks': 'online-denmark-banks',
    'online-austria-banks': 'online-austria-banks',
    'online-switzerland-banks': 'online-switzerland-banks',
    'online-eastern-europe-banks': 'online-eastern-europe-banks',
    'online-southern-europe-banks': 'online-southern-europe-banks',
    'online-italian-banks': 'online-italian-banks',
    'online-germany-banks': 'online-germany-banks',
    'online-portugal-banks': 'online-portugal-banks',
    'online-spain-banks': 'online-spain-banks',
    'online-south-africa-banks': 'online-south-africa-banks',
    'online-ecuador-banks': 'online-ecuador-banks',
    'online-chile-banks': 'online-chile-banks',
    'online-ghana-banks': 'online-ghana-banks',
    'online-cameroon-banks': 'online-cameroon-banks',
    'online-senegal-banks': 'online-senegal-banks',
    'online-kenya-banks': 'online-kenya-banks',
    'online-sweden-banks': 'online-sweden-banks',
    'online-finland-banks': 'online-finland-banks',
    'online-czech-banks': 'online-czech-banks',
    'online-estonia-banks': 'online-estonia-banks',
    'online-lithuania-banks': 'online-lithuania-banks',
    'online-slovakia-banks': 'online-slovakia-banks',
    'online-poland-banks': 'online-poland-banks',
    'online-netherlands-banks': 'online-netherlands-banks',
    'online-latvia-banks': 'online-latvia-banks',
    'online-belgium-banks': 'online-belgium-banks',
    'online-pakistan-banks': 'online-pakistan-banks',
    'cash-colombia': 'cash',
    'cash-brazil': 'cash',
    'cash-chile': 'cash',
    'cash-mexico': 'cash',
    'cash-spain': 'cash',
    'cash-portugal': 'cash',
    'cash-ecuador': 'cash',
    'cash-peru': 'cash',
    'cash-uruguay': 'cash',
    'cash-panama': 'cash',
    'cash-paraguay': 'cash',
    'cash-sierra-leone': 'cash',
    ligdicash: 'ligdicash',
    upi_short: 'upi-short',
    astropay: 'astropay',
    innopay: 'innopay',
    card_sbp: 'sbp',
    lottery: 'lottery',
    promptpay: 'promptpay',
    true_money: 'true_money',
    momo_pay: 'momopay',
    skypay: 'skypay',
    tether_erc20: 'tether_erc20',
    tether_trc20: 'tether_trc20',
    tether_bep20: 'tether_bep20',
    pay4fun: 'pay4fun',
    korti_milli: 'korti_milli',
    pay_zapp: 'pay_zapp',
    pix_unlimint: 'pix',
    paydo_ewallet: 'paydoewallet',
    grabpay: 'grabpay',
    wechatpay: 'wechatpay',
    alipay: 'alipay',
    sepa: 'sepa',
    usdc_bep20: 'usdc_bep20',
    usdc_erc20: 'usdc_erc20',
    usdc_trc20: 'usdc_trc20',
    bnb_bep20: 'bnb_bep20',
    bnb_dex: 'bnb_dex',
    busd_bep20: 'busd_bep20',
    busd_erc20: 'busd_erc20',
    'online-southkorea-banks': 'online-southkorea-banks',
    nagad: 'nagad',
    nagad_onewin: 'nagad-onewin',
    bank_of_india: 'bank-of-india',
    bank_of_maharashtra: 'bank-of-maharashtra',
    canara_bank: 'canara-bank',
    central_bank_of_india: 'central-bank-of-india',
    city_union_bank: 'city-union-bank',
    cosmos_bank: 'cosmos-bank',
    deutsche_bank: 'deutsche-bank',
    development_credit_bank: 'development-credit-bank',
    dhanlaxmi_bank: 'dhanlaxmi-bank',
    federal_bank: 'federal-bank',
    hdfc_bank: 'hdfc-bank',
    icici_bank: 'icici-bank',
    idfc_bank: 'idfc-bank',
    indian_bank: 'indian-bank',
    indian_overseas_bank: 'indian-overseas-bank',
    indusind_bank: 'indusind-bank',
    industrial_development_bank_of_india: 'industrial-development-bank-of-india',
    jammu_and_kashmir_bank: 'jammu-and-kashmir-bank',
    karnatka_bank: 'karnatka-bank',
    karurvysya_bank: 'karurvysya-bank',
    kotak_bank: 'kotak-bank',
    lakshmi_vilas_bank: 'lakshmi-vilas-bank',
    punjab_national_bank: 'punjab-national-bank',
    shamrao_vithal_cooperative_bank: 'shamrao-vithal-cooperative-bank',
    south_indian_bank: 'south-indian-bank',
    state_bank_of_india: 'state-bank-of-india',
    tamilnad_mercantile_bank: 'tamilnad-mercantile-bank',
    uco_bank: 'uco-bank',
    yes_bank: 'yes-bank',
    punjab_and_sind_bank: 'punjab-and-sind-bank',
    standard_chartered_bank: 'standard-chartered-bank',
    union_bank_of_india: 'union-bank-of-india',
    bank_of_baroda: 'bank-of-baroda',
    rocket: 'rocket',
    rocket_onewin: 'rocket-onewin',
    qiwi_h2h: 'qiwi',
    yamoney_h2h: 'yandex',
    'online-japan-banks': 'online-japan-banks',
    'community-bank-transfer': 'community-bank-transfer',
    'instant-qr': 'instant-qr',
    clapay_ci_mtn: 'mtn',
    clapay_cm_mtn: 'mtn',
    clapay_bj_mtn: 'mtn',
    clapay_gn_mtn: 'mtn',
    clapay_ml_malitel: 'malitel',
    clapay_bf_mobicash: 'mobicash',
    clapay_ci_moov: 'moov_money',
    clapay_bj_moov: 'moov_money',
    clapay_tg_moov: 'moov_money',
    clapay_ci_orange: 'orange_money',
    clapay_bf_orange: 'orange_money',
    clapay_cm_orange: 'orange_money',
    clapay_ml_orange: 'orange_money',
    clapay_gn_orange: 'orange_money',
    clapay_sn_orange: 'orange_money',
    clapay_sn_tigo: 'tigo',
    clapay_sn_tigo_freemoney: 'free_money',
    clapay_sn_emoney: 'emoney',
    clapay_tg_togo_com: 'togocom',
    clapay_sn_wave: 'wave',
    clapay_sn_wizall: 'wizall',
    bank_transfer_main: 'bank_transfer_main',
    picpay: 'picpay',
    pse: 'pse',
    walmart: 'walmart',
    waldos: 'waldos',
    seven_eleven: 'seven_eleven',
    soriana: 'soriana',
    comercial_mexicana: 'comercial_mexicana',
    bancomer: 'bancomer',
    bodega_aurrera: 'bodega_aurrera',
    sams_club: 'sams_club',
    superama: 'superama',
    calimax: 'calimax',
    tiendas_extra: 'tiendas_extra',
    circulo_k: 'circulo_k',
    telecomm: 'telecomm',
    banorte: 'banorte',
    farmacias_benavides: 'farmacias_benavides',
    farmacias_del_ahorro: 'farmacias_del_ahorro',
    el_asturiano: 'el_asturiano',
    alsuper: 'alsuper',
    kiosko: 'kiosko',
    farmacias_santa_maria: 'farmacias_santa_maria',
    farmacias_la_mas_barata: 'farmacias_la_mas_barata',
    farmacias_roma: 'farmacias_roma',
    farmacias911: 'farmacias911',
    farmacias_economicas: 'farmacias_economicas',
    farmacias_medicity: 'farmacias_medicity',
    rianxeira: 'rianxeira',
    western_union: 'western_union',
    zona_pago: 'zona_pago',
    card2card: 'card2card',
    imps_einpayz: 'imps',
    imps_interkassa: 'imps',
    gpay_einpayz: 'gpay',
    phone_pe_einpayz: 'phone-pe',
    upi_einpayz: 'upi',
    paytm_einpayz: 'paytm',
    bhim: 'bhim',
    whatsapp_pay: 'whatsapp_pay',
    paytm_upi: 'paytm_upi',
    pagstar: 'pagstar',
    easypaisa: 'easypaisa',
    jazzcash: 'jazzcash',
    upi_payments_ng: 'upi',
    upi_interkassa: 'upi',
    todito: 'todito',
    icbc: 'icbc',
    bbva: 'bbva',
    nagad_p2c: 'nagad',
    cashmaal: 'cashmaal',
    emanat: 'emanat',
    hay_hay: 'hay_hay',
    smartpay_card: 'smartpay_card',
    indonesia_va: 'indonesia_va',
    upay: 'upay',
    'eziopay-crypto': 'eziopay-crypto',
    'eziopay-crypto-p2p': 'eziopay-crypto',
    netbanking_t365: 'online-india-banks',
    south_korea_bank_transfer: 'south-korea-bank-transfer',
    vietnam_bank_transfer: 'vietnam-bank-transfer',
    virtual_accounts: 'virtual_accounts',
    thai_qr: 'thai_qr',
    paytm_game_changer_onewin: 'paytm-onewin',
    upi_elvis_onewin: 'upi-onewin',
    upi_game_changer_onewin: 'upi-onewin',
    phonepe_elvis_onewin: 'phone-pe-onewin',
    phonepe_game_changer_onewin: 'phone-pe-onewin',
    phonepe_sunil_onewin: 'phone-pe-onewin',
    gpay_elvis_onewin: 'gpay-onewin',
    gpay_game_changer_onewin: 'gpay-onewin',
    bank_transfer_game_changer_onewin: 'bank-transfer-onewin',
    'bkash-no-fields': 'b-kash',
    paytm_upi_onewin: 'paytm_upi_onewin',
    pay_zapp_onewin: 'pay_zapp_onewin',
    mobikwik_onewin: 'mobikwik_onewin',
    airtel_onewin: 'airtel_onewin',
    freecharge_onewin: 'freecharge_onewin',
    whatsapp_pay_onewin: 'whatsapp_pay_onewin',
    amazon_pay_onewin: 'amazon_pay_onewin',
    bharatpe_onewin: 'bharatpe_onewin',
    m10: 'm10',
    tpaga: 'tpaga',
    card_jcb: 'jcb',
    clapay_ci_wave: 'wave',
    elo: 'elo',
};

export const cardTypesIconMap = {
    visa: 'visa',
    mastercard: 'mastercard',
    mir: 'mir',
};

export const AVAILABLE_PAYMENT_INSTRUCTIONS = ['btc_usd', 'fk_wallet'];

export const INSTRUCTION_URL_BY_PAYMENT_METHOD = {
    fk_wallet: 'https://www.fkwallet.com/',
    btc_usd: 'https://www.blockchain.com/',
};

export const DEFAULT_APP_TITLE_TEXT = '1win';
export const DEFAULT_APP_POKER_TITLE_TEXT = '1win – Покер';

export const META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY = Symbol('Not allowed for reduced site functionality bets only');
export const META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER = Symbol('Not allowed for reduced site functionality poker');
export const META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST = Symbol('Not allowed for reduced site functionality invest');
export const META_NOT_ALLOWED_FOR_BLOCKABLE_DOMAIN = Symbol('Not allowed for blockable domain');
export const META_FORCE_CHANGE_LANG = Symbol('Force change lang if needed');
export const META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES = Symbol('Reload page only for major updates');

export const META_USE_GAME_RELATED_VIEW = Symbol('Use game related view');

/* permissions */
export const PERMISSION_GAME_NAME_1_WIN_TV_ALLOWED = '1win-tv-allowed';
export const PERMISSION_GAME_NAME_PARSER_ALLOWED = 'parser-allowed';
export const PERMISSION_WITHDRAWAL_BLOCKED = 'withdrawal_block';
export const PERMISSION_1WIN_TESTER = '1win_tester';
/* -- permissions -- */

/* router related constants */
export const ROUTE_META_CUSTOM_SUPPORT_PANEL = Symbol('CUSTOM_SUPPORT_PANEL');
export const ROUTE_META_HIDE_SUPPORT_PANEL = Symbol('HIDE_SUPPORT_PANEL');
export const ROUTE_META_HIDE_DIVIDENDS_MODAL = Symbol('HIDE_DIVIDENDS_MODAL');
export const ROUTE_META_CUSTOM_HEADER_BOTTOM = Symbol('CUSTOM_HEADER_BOTTOM');
export const ROUTE_META_CUSTOM_HEADER = Symbol('CUSTOM_HEADER');
export const ROUTE_META_CUSTOM_FOOTER = Symbol('CUSTOM_FOOTER');
export const HEADER_INVEST = Symbol('HEADER_INVEST');
export const HEADER_CASINO_GAME = Symbol('HEADER_CASINO_GAME');
export const HEADER_RULES = Symbol('HEADER_RULES');
export const HEADER_LEADERBOARD = Symbol('HEADER_LEADERBOARD');
export const FOOTER_LEADERBOARD = Symbol('FOOTER_LEADERBOARD');
export const NO_STICKY_HEADER = Symbol('NO_STICKY_HEADER');
export const NO_SCROLL_CASINO_PAGE = Symbol('NO_SCROLL_CASINO_PAGE');
export const OFFSET_HEADER_ITEM_FROM_BLUR = Symbol('OFFSET_HEADER_ITEM_FROM_BLUR');
/* -- router related constants -- */

const V_INPUT_CUSTOM_MESSAGE_KEYS_SET = Object.freeze(new Set(['text', 'actionText', 'actionName', 'closeable']));
export function V_INPUT_CUSTOM_MESSAGE_PROP_VALIDATOR(prop) {
    return Object.keys(prop).every((key) => V_INPUT_CUSTOM_MESSAGE_KEYS_SET.has(key));
}

/* states */
export const STATE_INITIAL = 'initial';
export const STATE_LOADED = 'loaded';
export const STATE_LOADING = 'loading';
export const STATE_ERROR = 'error';
/* -- states -- */

// eslint-disable-next-line no-useless-escape
export const URL_PARSE_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

/* -- casino games related --> */
export const CASINO_GAMES_PREFER_REDIRECT = [
    'fundist_1727053',
];

// patterns that matches links that may occur in html before actual game link
export const GAME_BODY_LINKS_BLACKLIST = [
    /get\.adobe\.com/,
    /globexdns\.com/,
    /\.js$/,
];

export const PromotedGameIdEnum = Object.freeze({
    AVIATOR: 'aviator',
    SPRIBE_AVIATOR: 'spribe_aviator',
    JETX: 'mrslotty_smartsoft-jetx',
    SCOUT_FANTASY: 'scout_fantasy',
});

const createOneWinGameEnumItem = (id, useCommonGameLogic = false) => Object.freeze({
    id,
    useCommonGameLogic,
    toString() {
        return id;
    },
});

export class OneWinGameEnum {
    static LUCKYJET = createOneWinGameEnumItem(
        process.env.IS_BACKEND_DEVELOPMENT
            ? '1play_1play_aviator'
            : '1play_1play_luckyjet',
    );

    static ROCKETX = createOneWinGameEnumItem(
        process.env.IS_BACKEND_DEVELOPMENT
            ? '1play_1play_spacexxx'
            : '1play_1play_rocketx',
    );

    static BOMBUCKS = createOneWinGameEnumItem(
        process.env.IS_BACKEND_DEVELOPMENT
            ? '1play_1play_minefield'
            : '1play_1play_bombucks',
        true,
    );

    static LUCKYLOOT = createOneWinGameEnumItem(
        process.env.IS_BACKEND_DEVELOPMENT
            ? '1play_1play_more-less'
            : '1play_1play_luckyloot',
        true,
    );

    static SPEED_AND_CASH = createOneWinGameEnumItem('1play_1play_speed-n-cash');

    static #idToItemMap = new Map(Object.values(this).map((item) => [item.id, item]));

    static getById(id) {
        return this.#idToItemMap.get(id);
    }

    static includes(id) {
        return !!this.getById(id);
    }

    /**
     * The function returns whether we should use custom component for casino game or not.
     * @param id
     * @returns {boolean}
     */
    static isCustomLogicOneWinGame(id) {
        const oneWinGameItem = this.getById(id);
        return oneWinGameItem && !oneWinGameItem.useCommonGameLogic;
    }
}
/* <-- casino games related -- */

export const URL_STATE_PLACEHOLDER = 'URL_STATE_PLACEHOLDER';

export const BEFORE_FREE_SPINS_COUNT = 21;
export const FREE_SPINS_COUNT = 5;

/* invest */
export const CHART_MODE_MONTHLY = 'chart-mode-monthly';
export const CHART_MODE_DAILY = 'chart-mode-daily';

export const INVEST_DIVIDENDS_REQUEST_TIMEOUT = 300000; // 5 min
export const INVEST_MIN_FPS_FOR_ANIMATION = 30;
/* -- invest -- */

export const MOSCOW_TIMEZONE_OFFSET_M = 180;
export const MOSCOW_TIMEZONE_OFFSET_MS = MOSCOW_TIMEZONE_OFFSET_M * 60 * 1000;

export const DOMAIN_CONTENT_TIME_AWAIT_TIMEOUT = 3000;

export const sportTagKeyToIdCssGradientMap = Object.freeze({
    default: 'linear-gradient(to bottom, #FFFFFF, #FFFFFF)',
    call_of_duty: 'linear-gradient(to bottom, #ACACAC, #FFFFFF)',
    counter_strike: 'linear-gradient(to bottom, #B2BDD3, #6E84B0)',
    dota_2: 'linear-gradient(to bottom, #FF7D63, #FF342C)',
    fifa: 'linear-gradient(to bottom, #FFC5F2, #FFFFFF)',
    hearthstone: 'linear-gradient(to bottom, #FFED1E, #FF9900)',
    king_of_glory: 'linear-gradient(to bottom, #F2E8C7, #FFEAA4)',
    league_of_legends: 'linear-gradient(to bottom, #FCEAAD, #E2A447)',
    owerwatch: 'linear-gradient(to bottom, #FD811A, #FFFFFF)',
    rainbow_six: 'linear-gradient(to bottom, #ACACAC, #FFFFFF)',
    rocket_league: 'linear-gradient(to bottom, #5AAFFF, #003BBC)',
    wow: 'linear-gradient(to bottom, #FFE34E, #EE6942)',
    starcraft_ii: 'linear-gradient(to bottom, #F0F8FF, #6797FF)',
    warcraft_iii: 'linear-gradient(to bottom, #D5A037, #882D13)',
    // Smite: ['#FFEC87', '#C98200'],
    // Quake: ['#5A1009', '#E72B1A'],
    // osu: ['#F06EA8', '#F06EA8'],
    // NBA2K: ['#FF1500', '#FF6355'],
    // Valorant: ['#E55256', '#F93A3F'],
    // Street Fighter: ['#FF5D57', '#D53630'],
    // StarCraft BroodWar: ['#A4D9FF', '#FFFFFF'],
    // Arena of Valor: ['#F6F8EE', '#D4E299'],
});

export const BetStatusKey = Object.freeze({
    OPEN: 0,
    LOSE: 1,
    WIN: 2,
    REFUND: 3,
    SOLD: 4,
});

export const DetailingOrigin = Object.freeze({
    DEPOSITS: 1,
    WITHDRAWALS: 2,
    BETS: 3,
    CASINO: 4,
    CASES: 5,
});

export const DepositStatusKey = Object.freeze({
    PENDING: 0,
    SUCCESSED: 1,
    REJECTED: 2,
    PARTNER_SUCCESSED: 3,
    PARTNER_REJECTED: 4,
});

export const WithdrawalStatusKey = Object.freeze({
    PENDING: 0,
    SUCCESSED: 1,
    REJECTED: 2,
    PENDING_PAYMENT_SYSTEM: 3,
    CANCELED: 5,
});

export const DetailingIndicationiStyle = Object.freeze({
    GAIN: 'gain',
    LOSE: 'lose',
    SOFT_LOSE: 'soft-lose',
    RETURN: 'return',
});

export const DetailingSelection = Object.freeze({
    RESUME: 'resume',
    DEPOSITS: 'deposits',
    WITHDRAWALS: 'withdrawals',
    BETS: 'bets',
    CASES: 'cases',
    CASINO: 'casino',
});

export const CASINO_SLIDER_PROVIDER_NAME_LIST = [
    'NetEnt',
    'Evolution',
    'Habanero',
    'MrSlotty',
    'Bgaming',
    'Apollo',
    'Fugaso',
    'Booongo',
    'RedTiger',
    'Quickspin',
    'Spinomenal',
];

export const CASINO_PROVIDERS_SLIDER_GAMES_AMOUNT = 10;
export const CASINO_CATEGORY_SLIDERS_AMOUNT = 10;
export const CASINO_CATEGORY_SLIDER_GAMES_AMOUNT = 40;
export const CASINO_NAVIGATION_HIDDEN_CATEGORY_IDS = [
    CASINO_CATEGORIES.FAVORITES,
    CASINO_CATEGORIES.POPULAR,
];

export const WINNERS_QUEUE_LIST_MAX_LENGTH = 100;

export const explicitCyberSportOrder = Object.freeze([
    'dota_2',
    'counter_strike',
    'league_of_legends',
]);

/* Registration list config */

export const ALL_POSSIBLES_REG_TYPE_LIST = [
    REG_FULL,
    REG_SOCIAL,
    REG_ONE_CLICK,
    REG_MOBILE,
];

export const DEFAULT_REG_TYPE_LIST = [
    REG_FULL,
    REG_SOCIAL,
];

/* -- Registration list config -- */

export const CARD_FIELD = Object.freeze({
    NUMBER: 'card_pan',
    HOLDER: 'card_cardholder',
    CVV: 'cvv',
    EXPIRE_DATE: 'expire_date',
});

export const CARD_VIEW = Object.freeze({
    DEFAULT: 0,
    CARD: 1,
    CARD_WALLET_ONLY: 2,
    PAY_TM: 3,
    CARD_MULTIPLE_TYPE: 4,
    P2P: 5,
});

export const PAYMENT_METHODS_INTERAC = ['interac', 'interac-eto', 'interac-ach'];

export const FOOTER_PAYMENTS = Object.freeze([
    {
        iconName: 'visa',
        paymentMethodShortValue: 'card',
        cardType: 'visa',
    },
    {
        iconName: 'mastercard',
        paymentMethodShortValue: 'card',
        cardType: 'mastercard',
    },
    {
        iconName: 'googlepay',
        paymentMethodShortValue: 'googlepay',
    },
    {
        iconName: 'applepay',
        paymentMethodShortValue: 'applepay',
    },
    {
        iconName: 'btc',
        paymentMethodShortValue: 'btc',
    },
    {
        iconName: 'qiwi',
        paymentMethodShortValue: 'qiwi',
    },
    {
        iconName: 'ethereum',
        paymentMethodShortValue: 'eth',
    },
    {
        iconName: 'tether',
        paymentMethodShortValue: 'tether',
    },
    {
        iconName: 'skrill',
        paymentMethodShortValue: 'skrill',
    },
    {
        iconName: 'paytm',
        paymentMethodShortValue: 'paytm',
    },
    {
        iconName: 'payeer',
        paymentMethodShortValue: 'payeer',
    },
]);

export const FOOTER_PAYMENTS_EXTENSION_1 = Object.freeze([
    {
        iconName: 'piastrix',
        paymentMethodShortValue: 'piastrix',
    },
    {
        iconName: 'fk-wallet',
        paymentMethodShortValue: 'fk',
    },
    {
        iconName: 'webmoney',
        paymentMethodShortValue: 'webmoney',
    },
]);

export const FOOTER_PAYMENTS_EXTENSION_2 = Object.freeze([
    {
        iconName: 'muchbetter',
        paymentMethodShortValue: 'muchbetter',
    },
    {
        iconName: 'jbc',
        paymentMethodShortValue: 'card_short',
    },
    {
        iconName: 'discover',
        paymentMethodShortValue: 'card_short',
    },
    {
        iconName: 'interac',
        paymentMethodShortValue: 'interac',
    },
    {
        iconName: 'astropay',
        paymentMethodShortValue: 'card_short',
    },
]);

export const SPORTS_PROMOTION = Object.freeze([
    'uefa',
    'ufc',
    'wta',
    'fiba',
    'nhl',
    'atp',
]);

export const SPORTS_PROMOTION_EXTENSION = Object.freeze([
    'itf',
    'fifa',
]);

export const AB_GROUPS = Object.freeze({
    OLD_DEPOSITS_VIEW: 'old_deposits_view',
    NEW_DEPOSITS_VIEW: 'new_deposits_view',
});

export const RUSSIAN_LOCALE_ID = 1;

// ISO 3166-1 1win.pro site
export const COUNTRIES_FOR_BLOCK_WHOLE_SITE = ['RU', 'KP', 'IR', 'GB', 'DZ', 'KW', 'TT', 'PL', 'NL'];
export const COUNTRIES_SURELY_FORBIDDEN = ['BE', 'CH', 'DZ', 'ES', 'FR', 'GB', 'IR', 'LT', 'LV', 'KP', 'KW', 'NL', 'PL', 'SK', 'TT', 'US', 'RS', 'HU'];
export const COUNTRY_TO_DEFAULT_PAYMENT_MAP = {
    IN: 'paytm_onewin',
    UZ: 'humo_card',
};
export const countriesBlockedForSomeDomains = ['GP', 'GF', 'MQ', 'YT', 'RE', 'FR'];
export const countriesBlockedByDomain = {
    '1win.io': countriesBlockedForSomeDomains,
    '1woea.top': countriesBlockedForSomeDomains,
    '1win.com': countriesBlockedForSomeDomains,
    '1wrri.top': countriesBlockedForSomeDomains,
    '1win.com.ci': countriesBlockedForSomeDomains,
    '1wrwr.top': countriesBlockedForSomeDomains,
    '1wbae.xyz': countriesBlockedForSomeDomains,
    '1win.network': countriesBlockedForSomeDomains,
    '1win.gg': countriesBlockedForSomeDomains,
};

export const forceUnblockableDomains = ['1win.cloud'];
export const blockableDomains = ['1win.pro'];

export const CASINO_SECTION_GAMES_COUNT = 40;

export const ONE_WIN_COIN = '1win Coin';

export const LOYALTY_BONUS_ID = 54;

export const SocketCommunicationType = Object.freeze({
    COMMON: 'common',
    DEPOSIT: 'deposit',
});

export const REDUNDANT_MATCH_PUSH_EVENTS_AVOID_DELAY = 1000;
