/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
const isElement = (element) => element instanceof Element || element instanceof HTMLDocument;

export const selectElementText = (element) => {
    if (document.selection) {
        const range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
    } else if (window.getSelection) {
        const range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
    }
};

export const copyTextToBuffer = (text = '') => {
    const element = document.createElement('DIV');
    element.textContent = text;
    element.setAttribute('style', 'display: block !important; visibility: visible !important');
    document.body.appendChild(element);
    selectElementText(element);
    document.execCommand('copy');
    element.remove();
};

export const downloadTextAsFile = (text = [], fileName = 'file', type = 'text/plain') => {
    const file = new Blob(text, { type });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    element.remove();
};

export const random = (min, max) => Math.random() * (max - min) + min;

export const randomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;

export const randomFloat = (min, max, fixed = 0) => random(min, max).toFixed(fixed);

export const swipe = (el, index1, index2) => {
    [el[index1], el[index2]] = [el[index2], el[index1]];
};

export const numberFormat = (number, decimals, dec_point, thousands_sep) => {
    number = (`${number}`).replace(/[^0-9+\-Ee.]/g, '');
    const n = !Number.isFinite(+number) ? 0 : +number;
    const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep;
    const dec = (typeof dec_point === 'undefined') ? '.' : dec_point;
    const toFixedFix = (localN, localPrec) => {
        const k = 10 ** localPrec;
        return `${Math.round(localN * k) / k}`;
    };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    const s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
};

export const nf = (number, decimals = 0, dec_point = ',', thousands_sep = ' ') => numberFormat(number, decimals, dec_point, thousands_sep);

export const openWindowModal = (url) => {
    const newWindow = window.open(
        url,
        '_blank',
        'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1280,height=1024',
    );
    newWindow.opener = null;
};

export const checkEmail = (email) => /^([\w\d!#$%&'*+-/=?^_`{|}~]|"[^"]*?")+?@([\w\d-.]+|\[((\d{1,3}\.){3}\d{1,3}|[\d\w:]+)])$/i.test(email);

/**
 * @param querySelector {String|HTMLElement}
 * @param [params] {Object}
 * @param [params.delay=500] {Number}
 * @param [params.behavior=smooth] {String}
 * @param [params.windowMode] {Boolean}
 * @param [params.offset] {Number}
 */
export const scrollToQuerySelector = (querySelector, params) => {
    const entryParams = {
        delay: 500,
        behavior: 'smooth',
        ...params,
    };

    setTimeout(() => {
        const item = isElement(querySelector) ? querySelector : document.querySelector(querySelector);

        if (item) {
            if (entryParams.windowMode) {
                const topOfElement = item.getBoundingClientRect().top - entryParams.offset;

                window.scroll({
                    top: topOfElement,
                    behavior: entryParams.behavior,
                });
            } else {
                item.scrollIntoView({
                    behavior: entryParams.behavior,
                });
            }
        }
    }, entryParams.delay);
};

export const paramsToQuery = (params) => {
    const urlParams = new URLSearchParams('');

    Object.entries(params).forEach((it) => urlParams.set(...it));

    return urlParams.toString();
};
