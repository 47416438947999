export const TOKEN = 'token';
export const CASINO_BOT_TELEGRAM_HASH = 'casino_bot_telegram_hash';
export const SHOW_BALANCE = 'showBalance';
export const APP_MODE = 'app_mode';
export const CONSOLE_CONFIG = 'consoleConfig';
export const FORCE_LOGGING = 'force_logging';
export const CASES_THEME = 'cases-theme';
export const PREFER_COLLAPSED_ODDS_GROUPS = 'preferCollapsedOddsGroups';
export const TIMESTAMP_KEY = 'accessTimestamp';
export const REGISTER_FORM = 'register-form';
export const REGISTER_MODAL = 'registration-modal';
export const PWA_DOWNLOADED = 'pwa-downloaded';
export const USER_INTENTIONS_LOG = 'user-intensions-log';
export const IS_PWA_IGNORED = 'is-pwa-ignored';
export const IS_SOCIAL_WEB_AUTHORIZATION = 'is-social-web-authorization';
export const MAIN_NAVIGATION_PANEL_ITEMS_ORDER = 'main-navigation-panel-items-order';
export const FIREBASE_PUSH_API_TOKEN = 'FIREBASE_PUSH_API_TOKEN';
export const DEPLOYMENT_TARGET = 'deployment-target';
export const NEXT_APP_PROMO_SHOW_TIME = 'next-app-promo-show-time';
export const VERBOSE_UPDATE_LOGGING = 'verbose-update-logging';
export const MODAL_LOCALE_WAS_SHOWN = 'modal-locale-was-shown';
export const FORCE_REDIRECT_URL = 'force-redirect-url';
export const USE_ERUDA_CONSOLE = 'use-eruda-console';
export const OAUTH_KEY = 'oauth-key';
export const OAUTH_CLIENT = 'oauth-client';
export const AB_GROUP_REGISTRATION_MODAL = 'ab-group-registration-modal';
