import PushApiController from '@/services/PushApiService/PushApiController';

/**
 * Сейчас мы используем для веба data пуши, так как в таком случае мы имеем ручной контроль над показом пушей.
 * И можем избежать проблем с двойным показом пушей, когда у юзера старый сервис воркер (со старым кодом).
 * @singleton
 */
class WebPushApiService {
    /**
     * Объект абстрактного пуш апи сервиса
     * @type {object}
     */
    controller;

    /**
     * Объект внешнего API
     * @type {object}
     * @private
     */
    #messaging;

    /**
     * Объект регистрации сервис-воркера, необходимый для работы пушей через внешнее API
     * @type {ServiceWorkerRegistration}
     * @private
     */
    #registration;

    /**
     * Коллекция событий для правильной отписки при остановке сервиса
     * @type {Array<function(): void>}
     * @private
     */
    #subscriptions;

    constructor() {
        this.controller = new PushApiController({
            onStart: async () => {
                await this.#refreshToken();

                this.#subscriptions = [
                    /**
                     * Обработчик onMessage отлавливает только пуши когда сайт активен.
                     * Пуши для других состояний (свернуто, убит) работают через сервис воркер.
                     */
                    this.#messaging.onMessage(this.#parseMessage),
                    this.#messaging.onTokenRefresh(this.#refreshToken),
                ];
            },
            onStop: () => {
                this.#unsubscribe();
            },
            onIsPermissionsGranted: () => Promise.resolve(Notification.permission === 'granted'),
            onIsPermissionsDenied: () => Promise.resolve(Notification.permission === 'denied'),
            onCheckReadyState: () => {
                const isReady = [
                    this.#messaging,
                    this.#registration,
                ].every(Boolean);

                if (!isReady) {
                    return false;
                }

                this.#messaging.useServiceWorker(this.#registration);
                return true;
            },
            onDeleteToken: (token) => {
                if (token) {
                    try {
                        this.#messaging.deleteToken(token);
                    } catch (err) {
                        console.warn('Unable to delete firebase messaging token', err);
                    }
                }
            },
        });

        this.controller.registerServiceWorker = this.registerServiceWorker.bind(this);

        window.addEventListener('1w:firebaseLoaded', this.#initMessaging);
    }

    /**
     * Пробрасывает объект регистрации сервис-воркера
     * @param {ServiceWorkerRegistration} registration
     * @returns {void}
     */
    registerServiceWorker = (registration) => {
        this.#registration = registration;
        this.controller.checkReadyState();
    }

    /**
     * Инициализирует объект messaging
     * @returns {void}
     * @private
     */
    #initMessaging = () => {
        window.removeEventListener('1w:firebaseLoaded', this.#initMessaging);
        window.firebase.initializeApp({
            apiKey: process.env.FIREBASE_API_KEY,
            authDomain: process.env.FIREBASE_AUTH_DOMAIN,
            projectId: process.env.FIREBASE_PROJECT_ID,
            storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.FIREBASE_APP_ID,
            measurementId: process.env.FIREBASE_MEASUREMENT_ID,
        });

        try {
            this.#messaging = window.firebase.messaging();
            this.controller.checkReadyState();
        } catch (error) {
            console.error('Unable to initialize firebase messaging', error);
            this.controller.isReady.reject(error);
        }
    }

    /**
     * Разбирает полученное сообщение и генерирует пуш-уведомление для пользователя
     * @param {object} message
     * @returns {void}
     * @private
     */
    #parseMessage = (message) => {
        if (document.hidden) {
            return;
        }

        this.#registration.showNotification(message.data.title, {
            body: message.data.body,
            image: message.data.image,
            icon: '/img/icons/webpush-512x512.png',
            requireInteraction: true,
            tag: 'showOnlyLastPushTag',
            /* originalMessage - позволяет передать какие-нибудь данные в
             * "'notificationclick' листенер" в сервис воркере, который срабатывает при клике на пуш.
             * В частности используем его для получения линки для редиректа на нужную страницу.
             */
            data: { originalMessage: message.data },
        });
    }

    /**
     * Обновляет токен
     * @returns {Promise<void>}
     * @private
     */
    async #refreshToken() {
        const token = await this.#messaging.getToken();

        if (!token) {
            return;
        }

        const prevToken = this.controller.getTokenFromStorage();
        if (prevToken && prevToken !== token) {
            this.controller.deleteTokenCallback(prevToken);
        }

        this.controller.saveTokenCallback(token);
    }

    /**
     * Отписывается от всех событий
     * @returns {void}
     * @private
     */
    #unsubscribe() {
        this.#subscriptions?.forEach((unsubscribe) => {
            try {
                unsubscribe();
            } catch {
                // noop
            }
        });
    }
}

export default new WebPushApiService();
