import '@/utils/featureDetection'; // imported here for most early async test initiation

import {
    AppStorage,
    FORCE_REDIRECT_URL,
    IS_PWA_IGNORED,
    OAUTH_KEY,
    OAUTH_CLIENT,
} from '@/plugins/AppStorage';
import { cdpService } from '@/services';
import { PromiseEnum } from '@/store/promise';
import { googleTagManager } from '@/utils';
import { storeAuthToken } from '@/utils/token';
import { getQueryParametersFromUrl } from '@/utils/url';

import { createApp } from './app';

/**
 * @namespace {object} window
 *   @property {object} window.INITIAL_DATA
 *     @property {string} window.INITIAL_DATA.coreServerHost
 *     @property {string} window.INITIAL_DATA.ourLang
 *     @property {Record<string, string>} window.INITIAL_DATA.langData
 *     @property {Record<string, string>} window.INITIAL_DATA.domain
 *       @property {String} window.INITIAL_DATA.domain.content_type
 */

window.buildConfig = {
    BRANCH_NAME: process.env.BRANCH_NAME,
    BLOCKABLE: process.env.BLOCKABLE,
    BUILD_NAME: process.env.BUILD_NAME,
    CONSOLE_FORCE: process.env.CONSOLE_FORCE,
    BUILD_TIME: process.env.BUILD_TIME,
    CDN_URL: process.env.CDN_URL,
    LANG_TAG: process.env.LANG_TAG,
    LANG_PATH: process.env.LANG_PATH,
    SESSION_PATH: process.env.SESSION_PATH,
    NODE_ENV: process.env.NODE_ENV,
    PARSER_PUSH_HOST: process.env.PARSER_PUSH_HOST,
    RECAPTCHA_KEY: process.env.RECAPTCHA_KEY,
    SERVER_HOST: process.env.SERVER_HOST,
    SOCKET_PATH: process.env.SOCKET_PATH,
    DISABLE_SW_ACTUAL_DOMAIN_LOGIC: process.env.DISABLE_SW_ACTUAL_DOMAIN_LOGIC,
    SERVICE_BETS: process.env.SERVICE_BETS,
    SERVICE_MATCH_STORAGE: process.env.SERVICE_MATCH_STORAGE,
    IS_BACKEND_DEVELOPMENT: process.env.IS_BACKEND_DEVELOPMENT,
    IS_BACKEND_PRODUCTION: process.env.IS_BACKEND_PRODUCTION,
    SPORT_ICONS_HOST: process.env.SPORT_ICONS_HOST,
    PROXY_CDN_URL: process.env.PROXY_CDN_URL,
};

/**
 * Обрабатывает содержимое адресной строки и обновляет страницу при необходимости.
 * @returns {boolean}
 */
const checkQueryParams = () => {
    const { params, cleanUrl } = getQueryParametersFromUrl(
        process.env.AUTH_TOKEN_QUERY_KEY,
        'isPwaIgnored',
        'oauth_key',
        'oauth_client',
    );

    const {
        [process.env.AUTH_TOKEN_QUERY_KEY]: authToken,
        isPwaIgnored,
        oauth_key: oauthKey,
        oauth_client: oauthClient,
    } = params;

    if (isPwaIgnored) {
        AppStorage.set(IS_PWA_IGNORED, isPwaIgnored);
    }

    if (authToken) {
        storeAuthToken(authToken);
    }

    if (oauthKey) {
        AppStorage.set(OAUTH_KEY, oauthKey);
    }

    if (oauthClient) {
        AppStorage.set(OAUTH_CLIENT, oauthClient);
    }

    if (Object.keys(params).length) {
        window.location = cleanUrl;
        return true;
    }

    return false;
};

/**
 * Проверяет апп. сторадж на наличие FORCE_REDIRECT_URL.
 * Если такой имеется, то редиректит туда.
 * @returns {boolean}
 */
const checkForceRedirectUrl = () => {
    const redirectUrl = AppStorage.getOr(FORCE_REDIRECT_URL, null);
    if (redirectUrl) {
        window.location = redirectUrl;
        return true;
    }

    return false;
};

/**
 * Проверяет и устанавливает в стор платформу, на которой в данный момент работает приложение (electron или браузер)
 * @param {import('vuex').Store} store
 * @returns {void}
 */
const setIsDesktopApp = ({ store }) => {
    const isDesktopApp = Boolean(new URLSearchParams(window.location.search).get('desktop'));
    store.commit('setIsDesktopApp', isDesktopApp);
};

/**
 * Создаёт и запускает новый экземпляр приложения
 * @returns {Promise<void>}
 */
(async () => {
    if (checkForceRedirectUrl()) {
        return;
    }

    if (checkQueryParams()) {
        return;
    }

    const { app, store } = await createApp();

    setIsDesktopApp({ store });

    store.dispatch('initSocketClient');
    store.dispatch('initUser');
    store.dispatch('initLang');
    store.dispatch('initDomainContentType');
    store.dispatch('initAppHashData');
    store.dispatch('pwaDownloaded');
    store.dispatch('loadAuthToken');
    store.dispatch('connectToServer');
    store.dispatch('offer/get', 1);
    store.dispatch('offer/get', 2);
    store.dispatch('offer/get', 3);

    store.watch(
        (_, getters) => getters.currency,
        () => store.dispatch('getPwaAmount'),
        { immediate: true },
    );

    store.watch(
        (_, getters) => getters.userId,
        (next, prev) => {
            if (next != null || prev != null) {
                // Не отправляем событие, если при инициализации приложения пользователь не авторизован
                googleTagManager.setUserId(next);
            }
        },
        { immediate: true },
    );

    await Promise.all([
        store.getters.promiseByName[PromiseEnum.LANG],
        store.getters.promiseByName[PromiseEnum.AUTH],
        store.getters.promiseByName[PromiseEnum.APP_HASH_DATA],
    ]);

    store.getters.promiseByName[PromiseEnum.AUTH_TOKEN].then(() => {
        if (!store.getters.isAuthed) {
            cdpService.visit();
        } else {
            store.dispatch('setTrack');
        }
    });

    app.$mount('#app');

    window.dispatchEvent(new CustomEvent('1w:platformRootMounted'));
})();
