export const createDateObject = (datetime = new Date()) => {
    const dateObj = new Date(datetime);

    const minute = `${dateObj.getMinutes()}`.padStart(2, '0');
    const hour = `${dateObj.getHours()}`.padStart(2, '0');
    const date = `${dateObj.getDate()}`.padStart(2, '0');
    const month = `${dateObj.getMonth() + 1}`.padStart(2, '0');

    return {
        minute,
        hour,
        date,
        month,
        day: `${dateObj.getDay()}`,
        year: `${dateObj.getFullYear()}`,
        time: `${hour}:${minute}`,
        dateMonth: `${date}/${month}`,
    };
};

/**
 * @param {Date|number} date
 * @param {Date|number} dateLater
 * @returns {{hours: number, seconds: number, minutes: number, days: number}}
 */
export function getDateDiff(date, dateLater) {
    const dateTimestamp = typeof date === 'number'
        ? date
        : date.getTime();

    const dateLaterTimestamp = typeof dateLater === 'number'
        ? dateLater
        : dateLater.getTime();

    const result = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };

    if (dateLaterTimestamp < dateTimestamp) {
        return result;
    }

    let diff = dateLaterTimestamp - dateTimestamp;

    result.days = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff -= result.days * (1000 * 60 * 60 * 24);

    result.hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= result.hours * (1000 * 60 * 60);

    result.minutes = Math.floor(diff / (1000 * 60));
    diff -= result.minutes * (1000 * 60);

    result.seconds = Math.floor(diff / 1000);

    return result;
}
