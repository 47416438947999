export class FingerprintService {
    #apiToken;

    constructor(apiToken) {
        this.#apiToken = apiToken;
    }

    static #isPro(fingerprint) {
        return 'requestId' in fingerprint.value;
    }

    static #getFingerPrintData(fingerprint) {
        const { visitorId, confidence } = fingerprint.value;

        if (FingerprintService.#isPro(fingerprint)) {
            return {
                clientId: visitorId,
                confidence: confidence.score,
            };
        }

        return { visitorId };
    }

    async getFingerprint() {
        const getFingerprint = (lib) => lib.load({ token: this.#apiToken }).then((fp) => fp.get());

        const fingerprints = await Promise.allSettled([
            import('@fingerprintjs/fingerprintjs').then(getFingerprint),
            import('@fingerprintjs/fingerprintjs-pro').then(getFingerprint),
        ]);

        const result = {
            clientId: '',
            visitorId: '',
            userAgent: window.navigator.userAgent,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
        };

        for (const fingerprint of fingerprints) {
            if (fingerprint.status === 'fulfilled') {
                Object.assign(result, FingerprintService.#getFingerPrintData(fingerprint));
            }
        }

        return result;
    }
}

export const fingerprintService = new FingerprintService(process.env.FINGERPRINT_API_KEY);
