import { mix } from '@/utils/color';

export const colorsToCSSLinearGradient = (angle = 90, colors = []) => {
    const params = colors
        .map((x, i, t) => `${x} ${(i / (t.length - 1)) * 100}%`)
        .join(',');

    return `linear-gradient(${angle}deg, ${params})`;
};

/**
 * Calculate easing gradient
 * @param {array} colors Two colors in an array
 * @param {string} unit Unit of measure for the position of the gradient stop point
 * @param {number} from Starting position of the gradient stop point
 * @param {number} to End position of the gradient stop point
 * @param {number} polySteps Look here https://github.com/larsenwork/easing-coordinates
 * @returns {string} string with colors and positions, separated by commas
 */
export const getEasingGradient = ({
    colors,
    unit = '%',
    from = 0,
    to = 100,
    polySteps = 12,
}) => {
    const stops = Array.from({ length: polySteps + 1 }, (_, index) => {
        const x = index / polySteps;
        const y = 1 - Math.sin(1 - x); // https://github.com/d3/d3-ease#easeSinOut

        const stop = x * (to - from) + from;
        const color = mix(y, colors[1], colors[0]);

        return `${color} ${stop.toFixed(2)}${unit}`;
    });

    return stops.join(', ');
};
