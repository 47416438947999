import axios from 'axios';

const pingActualDomain = async (url) => {
    try {
        const res = await axios.get(`${url}/ping?1=${Date.now()}`);
        return res.data.trim() === 'ok';
    } catch {
        return false;
    }
};

export const getActualDomain = async () => {
    try {
        const res = await axios.get(process.env.ACTUAL_DOMAINS_URL + Date.now());
        for (const domain of res.data) {
            if (await pingActualDomain(domain.url)) { // eslint-disable-line no-await-in-loop
                return domain.url;
            }
        }
    } catch {
        // noop
    }

    return undefined;
};

export const awaitServiceWorkerActive = async () => {
    /**
     * navigator.serviceWorker.controller is null if page was hard reloaded.
     * This is part of the service worker specification.
     */
    if (!navigator.serviceWorker?.controller) {
        return new Promise(() => {});
    }

    try {
        const res = await axios.get('/sw-active');
        if (res.data.trim() === 'ok') {
            return true;
        }
    } catch {
        // noop
    }

    if ('serviceWorker' in navigator) {
        return new Promise((resolve) => setTimeout(resolve, 500, awaitServiceWorkerActive()));
    }

    return new Promise(() => {});
};
