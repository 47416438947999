import Cookies from 'js-cookie';

import { AUTH_COOKIE_NAME } from '@/constant/constants';
import { AppStorage, TOKEN } from '@/plugins/AppStorage';

export const storeAuthToken = (token) => {
    Cookies.set(AUTH_COOKIE_NAME, token, { expires: 365 });
    AppStorage.set(TOKEN, token);
};

export const removeAuthToken = () => {
    Cookies.remove(AUTH_COOKIE_NAME);
    AppStorage.remove(TOKEN);
};
