import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';

import PushApiController from '@/services/PushApiService/PushApiController';

/**
 * @singleton
 */
class ApkPushApiService {
    /**
     * Объект контроллера пуш апи сервиса
     * @type {object}
     */
    controller;

    /**
     * Объект роутера, используемы для редиректа пользователя на определенный роут при клике на пуш.
     * Объявляется при вызове функции registerRouter.
     * @type {object}
     * @private
     */
    #router;

    constructor() {
        this.controller = new PushApiController({
            onStart: () => {
                this.#registerAllListeners();
                PushNotifications.requestPermissions().then(PushNotifications.register);
            },
            onStop: async () => {
                await Promise.all([
                    PushNotifications.removeAllListeners(),
                    LocalNotifications.removeAllListeners(),
                ]);
            },
            onIsPermissionsGranted: () => LocalNotifications.checkPermissions().then(
                ({ display }) => display === 'granted',
            ),
            onIsPermissionsDenied: () => LocalNotifications.checkPermissions().then(
                ({ display }) => display === 'denied',
            ),
            onCheckReadyState: () => !!this.#router,
            onDeleteToken: () => {},
        });

        this.controller.registerRouter = this.registerRouter.bind(this);
        this.controller.isNotAllRequiredPluginsImplemented = this.isNotAllRequiredPluginsImplemented.bind(this);
    }

    /**
     * Функция проверяет все ли нужные пакеты установлены в апк для показа пуш нотификаций.
     * Могут быть не установлены когда apk старая, а статика из pushcode прилетела свежая.
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    async isNotAllRequiredPluginsImplemented() {
        try {
            await Promise.all([
                PushNotifications.requestPermissions(),
                LocalNotifications.checkPermissions(),
            ]);
            return false;
        } catch (err) {
            console.log('Not all required plugins are implemented to show push notifications');
        }
        return true;
    }

    /**
     * Функция регистрирует объект роутера.
     * @param {object} router
     * @returns {void}
     */
    registerRouter(router) {
        this.#router = router;
        this.controller.checkReadyState();
    }

    /**
     * Функция регистрирует все необходимые листенеры, которые необходимо навесить при старте пуш сервиса.
     * @returns {void}
     */
    #registerAllListeners = () => {
        PushNotifications.addListener('registration', ({ value: token }) => {
            this.controller.saveTokenCallback(token);
            this.controller.isRunning = true;
        });

        PushNotifications.addListener('registrationError', (error) => {
            console.error(`Error on registration push notifications: ${JSON.stringify(error)}`);
            this.controller.triggerError();
        });

        /**
         * Состояние сайта и логика получения пушей вида "{ id: ..., notification: { ... } }":
         * (Мы так и шлем, все кейсы покрыты, все хорошо)
         *     Активен
         *          Нативного пуша нет, вызывается листенер (используем LocalNotifications).
         *     Свернут
         *          Нативный пуш есть, листенер не вызывается.
         *     Убит
         *          Нативный пуш есть, листенер не вызывается.
         * Состояние сайта и логика получения пушей вида "{ id: ..., data: { ... } }":
         *     Активен
         *          Нативного пуша нет, вызывается листенер.
         *     Свернут
         *          Нативного пуша нет, вызывается листенер.
         *     Убит
         *          Нативного пуша нет, листенер не вызывается (проблемный кейс).
         *          "@capacitor/push-notifications" не поддерживает такой кейс.
         *          По идее это можно решить путем написания своего наследника FirebaseMessagingService, смотри доку
         *          https://capacitorjs.com/docs/apis/push-notifications#silent-push-notifications-data-only-notifications
         */
        PushNotifications.addListener('pushNotificationReceived', async (notification) => {
            try {
                await PushNotifications.removeAllDeliveredNotifications();
                await LocalNotifications.requestPermissions();
                await LocalNotifications.schedule({
                    notifications: [
                        {
                            title: notification.title,
                            body: notification.body,
                            // Хотим показывать только последний пуш.
                            // Локал нотификашка по айди заменит предыдущую лок. нотификашку с таким же айди.
                            id: 1,
                            smallIcon: 'notification_icon',
                            largeIcon: 'notification_icon',
                            extra: {
                                data: notification.data,
                            },
                        },
                    ],
                });
            } catch (error) {
                console.error(`Error in scheduling local notification: ${JSON.stringify(error)}`);
            }
        });

        /**
         * Состояние сайта и логика при клике для пушей вида "{ id: ..., notification: { ... } }":
         *     Активен
         *          Листенер НЕ вызывается при клике на пуш.
         *          Это логично ведь в таком кейсе мы сами создаем Local Notification, соответственно
         *          будем отлавливать клик в LocalNotifications.addListener('localNotificationActionPerformed', ...)
         *     Свернут
         *          Листенер вызывается при клике на пуш
         *     Убит
         *          Листенер вызывается при клике на пуш
         * Состояние сайта и логика при клике для пушей вида "{ id: ..., data: { ... } }":
         *     не проверял
         */
        PushNotifications.addListener('pushNotificationActionPerformed', ({ notification }) => {
            const link = notification.data?.link;
            if (link) {
                this.#router.push(link);
            }
        });

        /**
         * Обрабатываем клик по нами же созданному локальному пушу (LocalNotifications).
         * Мы его создаем когда сайт в состоянии "Активен".
         */
        LocalNotifications.addListener('localNotificationActionPerformed', ({ notification }) => {
            const link = notification.extra?.data?.link;
            if (link) {
                this.#router.push(link);
            }
        });
    }
}

export default new ApkPushApiService();
