import PartnerLink from '@/plugins/PartnerLink';
import { isPwa } from '@/plugins/Platform';
import { awaitServiceWorkerActive } from '@/utils/serviceWorker';

if (isPwa()) {
    const partnerLink = PartnerLink();
    if (partnerLink) {
        awaitServiceWorkerActive().then(() => fetch('/sw-cache/partner', {
            method: 'POST',
            body: JSON.stringify(partnerLink),
        }));
    }
}
