import AffiliateFrontendHook from '@1win/affiliate-frontend-hook';

import { isPwa } from '@/plugins/Platform';
import { awaitServiceWorkerActive } from '@/utils/serviceWorker';

import AppConfig from '@config/app.config';

// create hook
const affiliateFrontendHook = new AffiliateFrontendHook(`${AppConfig.server.host}/`);

if (isPwa()) {
    awaitServiceWorkerActive()
        .then(() => fetch('/sw-cache/partner'))
        .then((r) => r.json())
        .then((url) => {
            if (!url) {
                return Promise.reject(new Error('url not found'));
            }
            return affiliateFrontendHook.handle(new URL(url));
        })
        .catch(() => affiliateFrontendHook.handle(window.location));
} else {
    // handle visit
    affiliateFrontendHook.handle(window.location);
}

export default affiliateFrontendHook;
